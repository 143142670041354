@import '~/styles'

.rubric
  width: min-content
  height: 20px
  padding: 0 8px
  background-color: $gray-light
  border-radius: 20px
  transition: .3s background, .3s color

  display: flex
  align-items: center

  &:hover
    background: $orange
    color: $white

  &__name
    font-size: 10px
    line-height: 10px
    letter-spacing: 0.6px
    white-space: nowrap
    text-transform: uppercase
    color: $black

  &_size_lg
    +min-screen($screen-lg-down)
      height: 32px
      font-size: 14px
      padding: 8px 10px
