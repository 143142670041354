@import '~/styles'
  
.wrapper
  display: block
  height: $banner-height
  font-size: 15px
  color: $white
  text-align: center
  overflow-x: auto
  position: relative
  z-index: 100
  @include no-scrollbar

.inner
  height: 100%
  min-width: 100%
  display: inline-block
  align-items: center
  white-space: nowrap

  svg
    line-height: 30px
    margin-right: 7px

.title
  line-height: $banner-height
  font-weight: 700
  margin-right: 10px

.subtitle
  line-height: $banner-height

.mainContent
  display: inline-block
  padding: 0 12px

.dublicateContent
  display: inline-block
  padding: 0 12px