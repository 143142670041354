.extended_link_tooltip
  position: absolute

  width: 300px
  padding: 12px 12px 12px 20px
  border: 1px solid #00000014
  background: white
  border-radius: 8px
  box-shadow: 0 16px 32px -6px #00000040
  z-index: 10
  cursor: pointer

  display: flex
  gap: 20px

  transition: .3s all
  opacity: 0

  .text_container
    flex-grow: 1

    &__description
      font-size: 16px
      line-height: 110%
      margin-bottom: 8px
      word-break: break-word

    &__url
      font-size: 14px
      line-height: 110%
      color: #00000066
      margin: 0
      word-break: break-word

  .icon_container
    flex-shrink: 0
