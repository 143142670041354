@import '~/styles/'

.modal
  z-index: 100
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(0, 0, 0, .7)
  &__content
    outline: none
    position: fixed
    top: 50%
    left: 50%
    right: auto
    bottom: auto
    margin-right: -50%
    transform: translate(-50%, -50%)
    display: flex
    width: 100%
    max-width: 460px
    padding: 44px 32px 36px 32px
    flex-direction: column
    align-items: center
    gap: 24px
    border-radius: 16px
    background: rgb(255, 255, 255)
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08)

.icon
  &_close
    position: absolute
    top: 16px
    right: 16px
    & svg
      fill: #A5A5A5
    &:hover svg
      fill: $orange
  &_back
    position: absolute
    top: 16px
    left: 16px
    & svg
      fill: #A5A5A5
    &:hover svg
      fill: $orange
  &_eye
    position: relative
    top: 6px

.btn
  display: flex
  padding: 12px 16px
  align-items: center
  justify-content: center
  gap: 12px
  border-radius: 4px
  align-self: stretch
  width: 100%
  height: 48px
  &_enabled
    color: $white
    background: $orange
    &:hover
      background: #FF764E
  &_disabled
    background: #E3E2E2
    color: #A5A5A5
  &_provider
    gap: 12px
    border: 1px solid #000
    background: $white
    &:hover
      background: #FF764E
  &_link
    color: #A5A5A5
    height: 0
    margin-top: 12px

.fullWidth
  width: calc(100% - 24px)

.center
  display: flex
  text-align: center

.content
  line-height: 24px

.form
  display: flex 
  flex-direction: column
  align-items: center
  gap: 12px
  width: 100%

.inputText
  padding: 12px 16px
  align-items: center
  gap: 12px
  border-radius: 4px
  border: 1px solid #000
  background: $white
  width: 100%
  height: 40px
  &_password
    align-items: center
    gap: 12px
    border-radius: 4px
    border: 1px solid #000
    background: $white
    width: 100%
    height: 40px
    padding: 0 16px
  &_invalid
    // color: red


.title
  align-self: stretch
  color: #000
  text-align: center
  font-feature-settings: 'clig' off, 'liga' off
  font-size: 32px
  font-style: normal
  font-weight: 700
  line-height: 32px

.error
  color: #D30B0B
  font-feature-settings: 'clig' off, 'liga' off
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 16px
  align-items: left
  width: 100%
  margin-top: -8px

.info
  width: 100%
  margin-top: -8px
  color:#7F7F7F
  font-feature-settings: 'clig' off, 'liga' off
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 16px
