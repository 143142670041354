@import '~/styles'

.menu
  position: sticky
  display: flex
  flex-direction: column
  justify-content: space-between
  top: 0
  bottom: 0
  left: auto
  width: $menu-w
  z-index: 100
  border-right: 1px solid $border-light
  touch-action: none
  overflow: hidden

  height: 100vh
  @supports (height: 100dvh)
    height: 100dvh

  +max-screen($screen-md)
    &__with_banner
      padding-top: $banner-height

    .menu_logo_wrapper
      padding: 0 12px
      border-bottom: 1px solid $white-12
      width: 100vw
      overflow: hidden

      display: flex
      justify-content: space-between
      align-items: center

      .close_button
        height: 24px

  &__with_banner
    height: calc(100vh - $banner-height)

    @supports (height: 100dvh)
      height: calc(100dvh - $banner-height)

  &_logo_wrapper
    height: 64px
    border-bottom: 1px solid $border-light
    padding-left: 30px

    display: flex
    align-items: center
    flex-shrink: 0
    flex-grow: 1

  &_logo
    width: 168px
    background-color: white
    +max-screen($screen-md)
      background-color: black
    &_dark
      background-color: $bg-dark
      +max-screen($screen-md)
        background-color: $bg-dark

  &_wrap
    display: flex
    flex-direction: column
    width: 100%
    overflow: hidden

  &_header
    .header_overlay
      display: none !important
    +min-screen($screen-md)
      [name="end"]
        opacity: 0

  &_list
    padding: 20px 30px
    overflow-y: scroll
    overflow-x: hidden

    +min-screen($screen-md)
      @include no-scrollbar

  &_search_container
    display: none
    flex-direction: column
    gap: 10px
    overflow: hidden

    +max-screen($screen-md)
      display: flex
      gap: 20px

      @media (max-height: 650px)
        gap: 14px

  &_topics
    display: flex
    flex-direction: column
    gap: 10px
    text-transform: uppercase

    +max-screen($screen-md)
      gap: 20px

      @media (max-height: 650px)
        gap: 14px

  &_link
    display: flex
    gap: 8px
    overflow: hidden
    width: fit-content
    white-space: nowrap
    font-size: 20px
    text-transform: uppercase

    > p
      font-size: 20px

    @media (hover: hover) and (pointer: fine)
      &:hover
        color: $purple-dark

  &_link_active
    color: $purple-dark

  &_email_subscribe_wrapper
    padding: 30px
    display: flex
    flex-direction: column
    gap: 20px
    border-top: 1px solid $white-8
    width: 100%

    +max-screen($screen-md)
      padding: 14px 16px 24px 16px
      text-wrap: nowrap

    @media (max-height: 650px)
      gap: 14px

  &_email_subscribe_wrapper_inner
    display: flex
    flex-direction: column
    gap: 12px
    overflow: hidden

    +max-screen($screen-md)
      white-space: nowrap
      gap: 16px

  +max-screen($screen-md)
    position: fixed
    top: 0
    bottom: 0
    left: 0
    max-width: 100%
    height: unset
    width: 100%
    background-color: #000
    color: $white
    margin: 0 !important

    &-hide
      &-false
        width: 100%
        opacity: 1
        transition: 0.5s width, 0.1s opacity

      &-true
        width: 0
        pointer-events: none
        overflow-x: hidden
        overflow-y: auto
        opacity: 0
        transition: 0.5s width, 0.1s opacity 0.5s

    &_header
      position: relative
      width: 100vw
      &:after
        content: ''
        position: absolute
        bottom: 0
        left: $padd
        display: block
        width: calc(100% - #{$padd * 2})
        height: 1px
        background-color: currentColor

    &_list
      padding: 32px 16px 16px 16px

      @media (max-height: 650px)
        padding-top: 14px

    &_activeLink:hover
      color: rgb(255, 255, 255) !important

.reminder_subscription_container
  display: none

  flex-direction: column
  gap: 20px
  overflow: hidden
  white-space: nowrap

  +max-screen($screen-md)
    display: flex

  svg
    width: 24px
    height: 24px
    flex-shrink: 0

  &_link
    display: flex
    align-items: center
    gap: 4px

    color: $purple-dark

.email_subscription_container
  position: relative

  .email_text_field
    background-color: $gray-light
    padding: 0 46px 0 10px
    border-radius: 4px
    color: $black

    font-size: 14px
    line-height: 15px
    height: 40px

    display: block
    width: 100%

    &::placeholder
      color: $black-40

    +max-screen($screen-md)
      padding: 0 50px 0 14px
      height: 48px
      background-color: $neutral-30
      color: $white

      &::placeholder
        color: $white

  .email_submit_button
    position: absolute
    top: 50%
    transform: translateY(-50%)
    right: 10px

    display: flex
    justify-content: center
    align-items: center
    background-color: $white

    width: 28px
    height: 28px
    border-radius: 50%

    +max-screen($screen-md)
      right: 14px

.top_divider
  height: 1px
  background-color: $border-light

  +max-screen($screen-md)
    background-color: $white-12

  &__only_mobile
    display: none

    +max-screen($screen-md)
      display: block

.login_section
  display: none

  +max-screen($screen-md)
    display: block

.bottom_links_container
  display: block
  padding: 0 30px 30px 30px
  text-transform: uppercase

  +max-screen($screen-md)
    text-transform: unset
    display: none

.logo_header
  position: absolute
  z-index: -1
  font-size: 10px

.close_button
  display: none
  +max-screen($screen-md)
    display: block