@import '~/styles'

.container > *
    max-width: $content-max-w
    margin: 0 auto
    padding: 32px 16px 0 16px

    +max-screen($screen-lg-down)
        padding-top: 20px

    &:last-child
        padding-bottom: 32px
        &::after
            display: none

    &::after
        content: ''
        display: block
        margin-top: 32px
        width: 100%
        height: 1px
        background: $border-light

        +max-screen($screen-lg-down)
            margin-top: 20px

.no_divider
    &::after
        display: none
