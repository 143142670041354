.auth
    width: 100%
    font-weight: 400
    font-size: 55px
    line-height: 100%
    @media (max-width: 767px)
        font-size: 35px
        line-height: 100%
    &_tit
        padding-top: 15px
    &_notice
        padding-top: 35px
        font-size: 32px
        line-height: 100%
        @media (max-width: 767px)
            font-size: 24px
    ul
        padding-top: 15px
        display: flex
        flex-wrap: wrap
        gap: 10px
    &_btn
        font-size: 55px
        line-height: 50px
        text-transform: capitalize
        position: relative
        text-decoration: underline
        text-decoration-thickness: 2px
        text-underline-offset: 5px
        @media (max-width: 767px)
            font-size: 35px
            line-height: 100%
        &:hover
            text-decoration: none
        //span
        //    position: absolute
        //    top: calc(50% - 28px)
        //    right: -70px
        //    display: flex
        //    align-items: center
        //    justify-content: center
        //    width: 56px
        //    height: 56px
        //    border-radius: 10em
        //    overflow: hidden
        //    &::after
        //        content: ''
        //        width: 50%
        //        height: 50%
        //        background-repeat: no-repeat
        //        background-position: center
        //        background-size: contain
        //        color: #fff
        &.facebook
            color: #16549C
            &:hover
                color: #16549c
        //     span
        //         background-color: #16549c
        //         &::after
        //             background-image: url("~/images/soc/fb.svg")
        &.google
            color: #e74417
            &:hover
                color: #dc483b
        //    span
        //        background-color: #dc483b
        //        &::after
        //            background-image: url("~/images/soc/google.svg")
        &.vkontakte
            color: #2172D0
            &:hover
                color: #2172d0
        //    span
        //        background-color: #2172d0
        //        &::after
        //            background-image: url("~/images/soc/vk.svg")
        //            margin-bottom: -2px