@import '~/styles/'

.backdrop_fade_2
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  -webkit-backdrop-filter: blur(0px)
  backdrop-filter: blur(0px)
  z-index: 1

  transition: .2s backdrop-filter, .2s -webkit-backdrop-filter

  &_is_open
    -webkit-backdrop-filter: blur(12px)
    backdrop-filter: blur(12px)

.overlay
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  transform: translateY(100%)

  display: flex
  flex-direction: column

  transition: .3s transform ease-out
  z-index: -1

  max-height: 100vh
  overflow: hidden

  &_is_open
    transform: translateY(0)

  &_is_open_z_index
    z-index: 10

.empty_space
  height: 100%
  position: relative
  touch-action: none

  svg
    position: absolute
    bottom: 11px
    left: 50%
    transform: translateX(-50%)

.sticky_table_of_contents
  position: relative
  height: fit-content
  max-height: 93%
  flex-shrink: 0

  display: flex
  flex-direction: column
  gap: 24px

  width: 100%
  background: $white
  border-radius: 12px 12px 0 0
  background: white
  padding: 30px 30px 118px 30px
  overflow-y: scroll

  z-index: 1

.table_of_contents_title
  font-size: 24px
