@import '~/styles'

$extended-quote-pseudo-icon: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 66 66' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.871 57.0679C26.0416 58.4392 29.4194 59.1249 33.0045 59.1249C36.6179 59.1249 40.0135 58.4389 43.1914 57.067C46.3694 55.695 49.1338 53.8217 51.4847 51.4469C53.8355 49.0721 55.6965 46.2994 57.0679 43.1289C58.4392 39.9583 59.1249 36.5805 59.1249 32.9953C59.1249 29.382 58.4389 25.9864 57.067 22.8084C55.695 19.6304 53.8331 16.866 51.4812 14.5152C49.1294 12.1644 46.3638 10.3033 43.1844 8.932C40.005 7.56067 36.6086 6.875 32.9953 6.875C29.4102 6.875 26.0331 7.56097 22.8639 8.9329C19.6948 10.3048 16.9233 12.1667 14.5496 14.5186C12.1759 16.8705 10.3033 19.6361 8.932 22.8155C7.56067 25.9949 6.875 29.3912 6.875 33.0045C6.875 36.5896 7.56097 39.9668 8.93289 43.136C10.3048 46.3051 12.1782 49.0766 14.553 51.4503C16.9278 53.824 19.7004 55.6965 22.871 57.0679ZM40.8672 39.7556V32.33H37.85V31.8317C37.85 30.8017 38.144 29.9877 38.7319 29.3897C39.3509 28.7584 40.3102 28.3265 41.6099 28.094L41.0993 24.2566C38.7784 24.5556 37.0145 25.3696 35.8076 26.6986C34.6007 28.0275 33.9973 29.9047 33.9973 32.33V39.7556H40.8672ZM31.2587 39.7556V32.33H28.2415V31.8317C28.2415 30.8017 28.5355 29.9877 29.1234 29.3897C29.7423 28.7584 30.7171 28.3265 32.0478 28.094L31.4907 24.2566C29.1698 24.5556 27.406 25.3696 26.1991 26.6986C24.9922 28.0275 24.3888 29.9047 24.3888 32.33V39.7556H31.2587Z' fill='black'/%3E%3C/svg%3E%0A")

H2
    font-size: 30px
    font-weight: 700
    line-height: 110%
    letter-spacing: -1px
    margin-top: 54px
    margin-top: 41px
    margin-bottom: 25px

    *
        font-weight: 700 !important

    +max-screen($screen-lg-down)
        font-size: 26px
        line-height: 100%
        letter-spacing: -1px
        margin-top: 32px
        margin-top: 28px
        margin-bottom: 18px


H3
    font-size: 22px
    font-weight: 700
    line-height: 110%
    letter-spacing: -1px
    margin-top: 54px
    margin-top: 30px
    margin-bottom: 20px

    *
        font-weight: 700 !important

    +max-screen($screen-lg-down)
        font-size: 22px
        line-height: 100%
        letter-spacing: -1px
        margin-top: 32px
        margin-top: 24px
        margin-bottom: 16px

H4
    font-size: 20px
    font-weight: normal
    line-height: 1.3
    letter-spacing: 1.5px
    text-transform: uppercase
    min-height: 1.3em
    margin: 20px 0 0
    color: #959595

    +max-screen($screen-lg-down)
        font-size: 18px
        margin-top: 16px

P
    font-size: 20px
    line-height: 140%
    font-weight: 400
    margin-top: 20px

    +max-screen($screen-lg-down)
        font-size: 18px
        margin-top: 16px

MARK
    display: inline-flex
    border-radius: 4px
    padding: 4px 8px
    background-color: #EFEFEF
    line-height: 1

EM
    font-size: 18px

B
    font-weight: bold

UL, OL
    width: 100%
    margin: 20px 0 0
    line-height: inherit
    list-style-position: inside

    +max-screen($screen-lg-down)
        margin-top: 16px

    UL, OL
        margin-top: 0

UL
    list-style: none
    LI
        position: relative
        padding-left: 38px

        margin-bottom: 20px

        &:last-child
            margin-bottom: 0

        +max-screen($screen-lg-down)
            margin-bottom: 16px
            padding-left: 30px

        &::before
            content: ''
            position: absolute
            top: 12px
            left: 0
            width: 5px
            height: 5px
            background-color: #00000066
            border-radius: 50%

OL
    counter-reset: numberList
    list-style: none
    LI
        position: relative
        padding-left: 58px
        counter-increment: numberList

        margin-bottom: 14px

        &:last-child
            margin-bottom: 0

        +max-screen($screen-lg-down)
            margin-bottom: 12px
            padding-left: 38px

        &::before
            content: counter(numberList)
            position: absolute
            top: 0
            left: 12px
            top: 3px
            left: 0px
            width: 30px
            text-align: right

            font-size: 24px
            font-weight: 500
            line-height: 110%

            +max-screen($screen-lg-down)
                font-size: 20px
                line-height: 100%


LI
    width: 100%
    line-height: inherit
    min-height: 1em

    +max-screen($screen-lg-down)
        font-size: 18px

PRE
    font-family: 'PT Mono', monospace
    padding: 10px 0
    white-space: pre-wrap
    border-top: 1px solid #ccc
    border-bottom: 1px solid #ccc

[class~="text_size_small"]
    font-size: 16px
    margin-top: 2px
    line-height: 110%

    +max-screen($screen-lg-down)
        font-size: 14px

[class~="contentGroup"]
    padding: 28px 24px
    margin-top: 20px
    border-radius: 5px
    line-height: 1.3
    font-size: 20px
    line-height: 140%
    font-weight: 400

    +max-screen($screen-lg-down)
        padding: 16px
        margin-top: 16px
        font-size: 18px

    > :first-child
        margin-top: 0

    h2, h3
        color: white
        background: black
        padding: 8px 12px
        border-radius: 4px
        width: fit-content

    OL, UL
        margin-top: 30px

        +max-screen($screen-lg-down)
            margin-top: 20px

:global(.extended-link)
    color: #FFF
    background: #000
    border-radius: 4px
    padding: 1px 3px
    cursor: pointer

[class~="contentGroup-grey"]
    background-color: #F2F2F2

[class~="contentGroup-purple"]
    background-color: #F7EEFF

[class~="contentGroup-orange"]
    background-color: #FFE3DB

[class~="contentGroup-beige"]
    background-color: #F4EEE7

[class~="contentGroup-quote"]
    box-sizing: border-box
    border-left: solid black 3px
    border-radius: 0
    padding: 0 0 0 30px
    color: rgb(182 136 228)
    p
        font-weight: 500
    a
        color: $purple
        border-bottom-width: 1px
        border-bottom-style: dotted
        border-bottom-color: currentColor
        cursor: pointer0
        &:hover
            border-bottom-style: solid
            color: $purple-dark

[class~="contentGroup-extended-quote"]
    background-color: #F2F2F2

    :global(.quote-author-container)
        display: flex
        align-items: center
        gap: 16px

        +max-screen($screen-lg-down)
            gap: 12px

        &::after
            content: $extended-quote-pseudo-icon
            display: block
            margin-left: 4px
            width: 66px
            height: 66px
            flex-shrink: 0
            align-self: start

            +max-screen($screen-lg-down)
                width: 32px
                height: 32px
                margin-left: 8px

    :global(.author-avatar)
        display: block
        width: 52px
        height: 52px
        border-radius: 50%
        object-fit: cover
        flex-shrink: 0

        +max-screen($screen-lg-down)
            width: 40px
            height: 40px

    :global(.text-container)
        flex-grow: 1

    :global(.extended-quote__author)
        font-size: 20px
        font-weight: 500
        line-height: 110%
        margin-top: 0

        +max-screen($screen-lg-down)
            font-size: 18px

    :global(.extended-quote__role)
        font-size: 14px
        font-weight: 400
        line-height: 110%
        margin-top: 10px

        +max-screen($screen-lg-down)
            font-size: 12px

FIGURE
    position: relative
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    margin-top: 1em
    margin-bottom: 0
    overflow: hidden

    PICTURE
        display: block
        overflow: hidden
        position: relative
        height: 100%
        width: 100%

        &:after
            content: ''
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            // background-color: rgba(0,0,0,0.05)
        DIV
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            // filter: blur(10px)
            background-position: center
            background-size: cover
            background-repeat: no-repeat
            background-color: $purple
        IMG
            position: absolute
            top: 0
            display: block
            margin: 0 auto
            width: 100%
            height: 100%
    FIGCAPTION
        width: 100%
        font-size: 13px
        margin-top: 3px
        opacity: 0.7
        &:before
            opacity: 0.5 !important

    &[class~="place-center"]
        max-width: 721px
        margin-left: auto
        margin-right: auto

    &[class~="place-fill"],
    &[class~="place-only_original"]
        width: 100%
        margin-left: 0
        margin-right: 0

    &[class~="place-wide"]
        margin-top: 2.5em !important
        margin-bottom: 2.5em !important
        margin-left: -220px
        margin-right: -220px
        +max-screen($screen-lg)
            margin: 0 !important
            margin-top: 1em !important
            width: 100%

    &[class~="place-preview_square"]
        width: 280px
        height: 280px
        +max-screen($screen-lg-down)
            width: 220px
            height: 220px
        +max-screen($screen-md-down)
            width: 190px
            height: 190px
        +max-screen($screen-sm-down)
            width: 95px
            height: 95px

    &[class~="place-cover"]
        +max-screen($screen-sm-down)
            picture
                width: 100%
                height: 100%
                div
                    width: 184%
                    height: 100%
                    transform: translateX(-50%)
                    margin-left: 50%
                img
                    width: auto
                    height: 100%
                    transform: translateX(-50%)
                    margin-left: 50%

[class~="embed"]
    display: flex
    justify-content: center
    width: 100%
    margin-top: 1em
    margin-bottom: 0

[class~="instagram-media-rendered"]
    width: 100%

[class~="separator"]
    cursor: pointer
    margin-bottom: -1em
    width: 100%
    padding: 26px 0
    div
        width: 100%
        height: 1px

    &[class~="mode-black"]
        div
            background-color: #000
    &[class~="mode-grey"]
        div
            background-color: #ccc

[class~="iframe"]
    margin-top: 1em
    margin-bottom: 0
    width: 100%

[class~="paywall_separator"]
    cursor: pointer
    border-bottom: 1px solid #000
    width: 100%
    height: 18px
    display: flex
    justify-content: center
    margin: 16px 0
    div
        &::after
            content: "Paywall"
            padding: 10px
            color: #fff
            border: 1px solid #000
            background-color: #f85b2e
            border-radius: 3px
