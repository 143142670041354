@import '~/styles'

.wrapper
  position: relative

  @media (max-width: 767px)
    overflow: visible

  .title
    margin-bottom: 12px
    font-size: 55px
    font-weight: 500
    line-height: 55px
    @media (max-width: 767px)
      font-size: 35px
      font-weight: 500
      line-height: 35px

  .container1
    margin: 0 auto
    padding-top: 10px

    .description
      margin-bottom: 30px
      font-size: 30px
      font-weight: 400
      line-height: 30px
      white-space: nowrap
      @media (max-width: 767px)
        font-size: 20px
        font-weight: 400
        line-height: 20px
        white-space: normal
        br
          display: none

.reports
  margin-bottom: 30px
  &__list
    display: flex
    flex-direction: column
    gap: 30px
  &__block
    &_text
      font-size: 1em
      line-height: 1.3
    &_author
      font-weight: bold
    &_position
      font-size: 14px
      line-height: 14px
      color: #A5A5A5

.btn
  display: flex
  width: 360px
  height: 52px
  padding: 12px 16px
  justify-content: center
  align-items: center
  gap: 12px
  border-radius: 4px
  background: $orange
  color: $white
  text-align: center
  font-feature-settings: 'clig' off, 'liga' off
  font-size: 20px
  font-style: normal
  font-weight: 400
  line-height: 24px
  margin: 30px 0
  @media (max-width: 1024px)
    width: 280px

.subscription_promo_error
  max-width: $content-max-w
  margin: 24px auto
  padding: 0 $content-padding-x

  +max-screen($screen-sm-down)
    padding: 0 16px

  display: flex
  flex-direction: column
  gap: 12px
  h1
    font-size: 55px
    font-weight: 500
    line-height: 55px
    @media (max-width: 767px)
      font-size: 35px
      font-weight: 500
      line-height: 35px
  .pay_button
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    row-gap: 18px
    padding: 18px 30px
    background: #FF6B40
    border: 1px solid #FF6B40
    border-radius: 4px
    align-self: stretch
    flex-grow: 1
    line-height: 14px
    text-align: center
    color: $white
    @media (min-width: 320px) and (max-width: 1023px)
      width: 280px
    @media (max-width: 767px)
      height: 47px
    @media (min-width: 1023px)
      max-width: 367px
    &:disabled
      background: #AAA
      color: $white
      border-color: #AAA
      cursor: not-allowed

.subscription_content_wrapper
  max-width: $content-max-w
  margin: 0 auto 81px auto
  padding: 0 $content-padding-x

  +max-screen($screen-lg-down)
    margin-bottom: 32px

  +max-screen($screen-sm-down)
    padding: 0 16px