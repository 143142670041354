@import '~/styles'

.reportHeader
  display: block
  position: relative
  margin: 0 -20px
  padding-bottom: 28px
  min-width: 320px
  background-color: rgb(0 0 0)
  +max-screen($screen-sm)
    padding-bottom: 80px
  &__noise
    pointer-events: none
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
  &__hr
    margin-bottom: 20px
    width: 100%
    height: 1px
    background-color: rgb(250 250 250)
  &__descriptionBlockWrapper
    z-index: 1
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 60%
    +max-screen($screen-sm)
      width: 100%
  &__content
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    width: 100%
    padding: 0 36px
    color: rgb(255 255 255)
    +max-screen($screen-sm)
      flex-direction: column
  &__title
    margin-top: 21px
    width: 100%
    font-weight: 500
    font-size: 50px
    line-height: 45px
    +max-screen($screen-md-down)
      font-size: 45px
      line-height: 40px
    +max-screen($screen-sm)
      font-size: 40px
      line-height: 35px
  &__price
    margin-top: 59px
    +max-screen($screen-sm)
      margin-top: 10px
  &__img
    width: 40%
    max-width: 390px
    transform:  translateX(0)
    border-radius: 4px
    box-shadow: (0px 24px 34px rgba(0, 0, 0, 0.25))
    +max-screen($screen-sm)
      width: 100%
      max-width: 390px
      height: auto
      margin: 30px 0 0 50%
      transform: translateX(-50%)
  &__button
    margin-top: 20px
    background-color: rgb(255, 255, 255)
    border-radius: 10px
    font-size: 20px
    line-height: 35px
    color: rgb(0, 0, 0)
    width: 211.34px
    height: 58.59px
  &__date
    margin-top: 19px
    font-size: 15px
    line-height: 18px
    width: 278px