@import '~/styles/'

.footer_bottom
  position: relative
  z-index: 1

  margin: 0 80px 32px 80px

  +max-screen($screen-sm)
    text-align: center
    margin: 0 16px 19px 16px

  .legal_info
    width: 100%
    color: white
    margin-bottom: 2px

  .bottom_line
    display: flex
    align-items: start
    gap: 500px
    color: $white
    margin-top: 16px

    +max-screen($screen-lg)
      gap: 0
      justify-content: space-between

    +max-screen($screen-sm)
      margin-top: 18px
      flex-direction: column
      align-items: center
      gap: 16px

    &_left_column
      display: flex
      gap: 8px

    &_item
      border-radius: 48px
      padding: 12px 16px

      display: flex
      justify-content: center
      align-items: center
      gap: 8px
      flex-shrink: 0

      > p
        line-height: 100%

    &_reminder
      border: 1px solid $white

    &_rss
      background: $black

  .it_summa_link
    display: flex
    align-items: flex-end
    gap: 9px
    flex-shrink: 0

    .developed_in_label
      +max-screen($screen-sm)
        font-size: 12px
        font-weight: 400
        line-height: 100%
        transform: translateY(-3px)

    >svg
      width: 112px

      +max-screen($screen-sm)
        width: 85px
