@import '~/styles/'

.button
  background: $purple-dark
  color: $white

  border-radius: 40px
  height: 40px
  padding: 0 16px

  transition: .2s background, .2s opacity

  display: flex
  justify-content: center
  align-items: center
  gap: 8px

  &:hover
    background: $purple

  &__secondary
    color: $black
    background: $gray-light

    &:hover
      background: $gray-light
      opacity: 0.8

  &__large
    height: 52px

  > *
    line-height: 100%
