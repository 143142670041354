// ----------------------------------------------------------------------
// Layout sizes
// ----------------------------------------------------------------------

$maxW: 1442px
$minW: 320px
$padd: 20px
$lg-screend-padd: 17px
$banner-height: 30px //высота баннера наверху сайта

$screen-lg: 1442px      // Это desktop_HD в дизайне
$screen-lg-down: 1291px // Тут мы переходим в desktop (вместо 1024), потмоу что картинки в ленте не влазят
$screen-md: 1024px
$screen-md-down: 960px
$screen-sm: 768px
$screen-sm-down: 640px // Тут мы переходим от айпада до айфона
$screen-xxs: 430px
$screen-xs: 320px

$main-md: 768px
$main-md-2: 960px
$main-xs: 640px

$menu-w: 310px
$logo-width: calc(100vw - 143px)

$content-padding-x: 16px
$content-max-w: calc(970px + $content-padding-x + $content-padding-x)
$header-height: 64px

$scrollbar-thumb: #888
$scrollbar-track: #f1f1f1
$scrollbar-thumb-hover: #555

// ----------------------------------------------------------------------
// Colors
// ----------------------------------------------------------------------

$orange: #F85B2E
$purple: #B688E4
$purple-dark: #974DE0
$blue: #25D3FF
$green: #0F6338
$white: #fff
$black: #000
$gray: #636363
$gray-light: #F2F2F2

$orange-hover: #EB5226
$purple-dark-hover: #772AC4

$tag-red: #FF1616
$tag-blue: #16ABFF
$tag-yellow: #FF8616
$tag-green: #23B755

$bg-dark: #181818

$neutral-30: #333333
$neutral-50: #F5F5F5
$neutral-70: #F2EDEA

$border-light: #00000014

$black-40: #00000066
$black-25: #00000040
$white-20: #FFFFFF33
$white-12: #FFFFFF1F
$white-8: #00000014
