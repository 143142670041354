@import '~/styles'

.reportsList
  &__header
    padding: 21px 0 0 0
    font-size: 55px
    line-height: 55px
    +max-screen(850px)
      font-size: 44px
    +max-screen($screen-md)
      display: none
  &__title
    color: $orange
  &__hr
    margin-top: 24.3px
    width: 100%
    height: 1px
    background-color: rgb(0, 0, 0)
  &__list
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    +max-screen($screen-sm-down)
      justify-content: center
      margin-left: 0
