@import '~/styles'


.subscription_promo_error
  display: flex
  flex-direction: column
  gap: 12px
  h1
    font-size: 55px
    font-weight: 500
    line-height: 55px
    @media (max-width: 767px)
      font-size: 35px
      font-weight: 500
      line-height: 35px
  .pay_button
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    row-gap: 18px
    padding: 18px 30px
    background: #FF6B40
    border: 1px solid #FF6B40
    border-radius: 4px
    align-self: stretch
    flex-grow: 1
    line-height: 14px
    text-align: center
    color: $white
    @media (min-width: 320px) and (max-width: 1023px)
      width: 280px
    @media (max-width: 767px)
      height: 47px
    @media (min-width: 1023px)
      max-width: 367px
    &:disabled
      background: #AAA
      color: $white
      border-color: #AAA
      cursor: not-allowed

.subscription_form
  display: flex
  flex-direction: row
  justify-items: flex-start
  gap: 12px
  margin-bottom: 30px
  @media (max-width: 1024px)
    gap: 34px
    flex-direction: column
    align-items: left

  .plan_button
    box-sizing: border-box
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    padding: 2px 10px
    gap: 2px
    font-weight: 500
    font-size: 30px
    line-height: 35px
    border-radius: 4px
    white-space: nowrap
    border: 1px solid #FF6B40
    color: #FF6B40
    height: 52px
    span
      opacity: 0.5
    &_annual
      width: 192px
      @media (max-width: 1024px)
        min-width: 280px
    &_monthly
      width: 212px
      background: $white
      @media (max-width: 1024px)
        min-width: 280px
    &_annual_discount
      width: 192px
      @media (max-width: 1024px)
        min-width: 280px
    &_monthly_discount
      width: 212px
      background: $white
      @media (max-width: 1024px)
        min-width: 280px
    &_active
      background: #FF6B40
      color: $white
    &_full_price
      border: 1px solid #FF6B40
      padding: 2px 10px
      position: absolute
      transform: translateY(32px)
      background: #FFFFFF
      color: rgba(0, 0, 0, .5)
      font-size: 18px
      box-shadow: 0 0 10px rgba(101,80,163,.7)
      border-radius: 4px
      line-height: 22px

  .advtitle
    font-size: 12px
    color: #FF6B40
    margin-top: 3px
    @media (min-width: 1024px)
      display: none

  .pay_button
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    row-gap: 18px
    padding: 18px 30px
    background: #FF6B40
    border: 1px solid #FF6B40
    border-radius: 4px
    align-self: stretch
    flex-grow: 1
    line-height: 14px
    text-align: center
    color: $white
    @media (min-width: 320px) and (max-width: 1023px)
      width: 280px
    @media (max-width: 767px)
      height: 47px
    @media (min-width: 1023px)
      max-width: 367px
    &:disabled
      background: #AAA
      color: $white
      border-color: #AAA
      cursor: not-allowed


.modal
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(0, 0, 0, .7)
  &__content
    outline: none
    position: fixed
    top: 50%
    left: 50%
    right: auto
    bottom: auto
    margin-right: -50%
    transform: translate(-50%, -50%)
    min-width: 300px
  &__close
    position: absolute
    top: 10px
    right: 10px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    width: 21px
    height: 21px
    background-color: rgb(0, 0, 0)

.payment_form_wrapper
  overflow-y: auto
  max-height: 95vh
  min-width: 285px
  margin: 0 5px

.payment_form
  padding-top: 40px
  margin: auto
  &_container
    padding: 20px
    background: #FFF
    border-radius: 10px

.form
  background-color: rgb(255, 255, 255)
  border-radius: 10px
  display: flex
  flex-direction: column
  align-items: center
  width: 500px

  +max-screen($screen-sm-down)
    max-width: 320px
    padding: 0

  &__content
    padding: 32px 37px 0

  box-sizing: border-box
  &__title
    max-width: 409px
    text-align: center
    font-size: 40px
    line-height: 45px
    font-weight: 500
    +max-screen($screen-sm-down)
      font-size: 25px
      line-height: normal
    &_ok
      display: flex
      flex-direction: column
  &__subTitle
    font-size: 25px
    margin-top: 10px
    font-weight: 400
    text-align: center
    +max-screen($screen-sm-down)
      font-size: 18px
      line-height: normal
  &__submit
    margin-top: 41px
    border-radius: 4px
    height: 59px
    width: 110%
    box-shadow: 0px 10px 15px rgba(0, 0, 0, .25)
    background-color: rgb(255, 255, 255)
    display: flex
    font-size: 20px
    +max-screen($screen-sm-down)
      margin-top: 20px
      width: 110%
    &_ok
      width: 165px
      justify-content: center
      align-items: center
      margin-bottom: -21.7px
  &__label
    font-size: 18px
    margin-top: 10px
    +max-screen($screen-sm-down)
      font-size: 14px
      line-height: normal
  &__input
    width: 100%
    box-sizing: border-box
    padding: 0 25px
    &::placeholder
      color: rgb(0, 0, 0)
  &__button
    width: 45.31%
    text-align: center
    border-left: solid rgb(226, 226, 226) 1px


.pay_button 
  &_wrapper
    margin: 14px auto -54px auto
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
  &_single
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    row-gap: 18px
    padding: 18px 30px
    background: #FF6B40
    border: 1px solid #FF6B40
    border-radius: 4px
    align-self: stretch
    flex-grow: 1
    line-height: 14px
    text-align: center
    color: $white
    width: 81%
    margin: 0 auto 1em
    transition: all 0.3s
    &:hover
      transform: scale(1.01)
    @media (max-width: 640px)
      width: 70vw
    @media (max-width: 380px)
      padding: 8px 18px  
    

  &_title
    width: 100%
    font-size: 24px
    white-space: nowrap
    margin-bottom: -10px
    color: $white !important
    @media (max-width: 380px)
      font-size: 20px

  &_subtitle
    width: 100%
    font-size: 14px
    white-space: nowrap
    color: $white !important
    @media (max-width: 380px)
      font-size: 12px
  &_paywall
    display: flex
    height: 52px
    padding: 12px 16px
    justify-content: center
    align-items: center
    gap: 12px
    align-self: stretch
    border-radius: 4px
    background: $orange
    color: $white
    margin: 8px 0
  
.plans_wrapper
  display: flex
  flex-direction: row
  align-items: flex-start
  gap: 40px
  align-self: stretch
  max-width: 721px
  margin-bottom: 48px
  @media (max-width: 768px)
      flex-direction: column
