@import '~/styles'

.subscription_form_item
  position: relative
  border-radius: 8px
  padding: 32px 36px
  background: $white
  flex-grow: 1
  flex-shrink: 0
  transition: .2s border-color, .2s box-shadow
  width: calc(50% - 8px)

  display: flex
  flex-direction: column
  gap: 20px
  max-width: 480px

  +max-screen($main-xs)
    padding: 32px 24px
    width: 100%

  &_is_annual
    box-shadow: 0 8px 32px 0 #F85B2E33

  .floating_discount_badge
    position: absolute
    top: -13px
    right: -9px

    padding: 8px 16px
    border-radius: 16px
    background: $purple-dark
    color: $white
    z-index: 1

    font-size: 30px
    line-height: 33px
    font-weight: 700

    +max-screen($main-xs)
      line-height: 26px
      padding: 4px 8px
      font-size: 24px
      top: -7px
      right: -9px
      border-radius: 12px

  .header
    display: flex
    justify-content: center
    align-items: center

  .title
    text-align: center
    font-size: 28px
    line-height: 21px
    font-weight: 500

  .separator
    height: 1px
    background: $border-light

    +max-screen($main-xs)
      display: none

  .prices_container
    text-align: center
    display: flex
    flex-direction: column
    gap: 4px

    .price_title
      display: flex
      justify-content: center
      gap: 1px
      white-space: nowrap
      font-size: 44px
      line-height: 100%

      .ruble_wrapper
        width: 30px
        height: 31px
        margin-left: 3px

    .price_subtitle
      display: flex
      justify-content: center
      gap: 1px
      color: $black-40
      font-size: 16px
      line-height: 12px

      +max-screen($screen-lg-down)
        gap: 0

      &_accent
        color: $orange

      .ruble_wrapper
        width: 12px
        height: 10px
        margin-left: 2px

        +max-screen($screen-lg-down)
          width: 9px
          height: 9px

  .description
    text-align: center
    font-size: 18px
    flex-grow: 1

@property --gradient-color-1
  syntax: '<color>'
  initial-value: #974DE0
  inherits: false

@property --gradient-color-2
  syntax: '<color>'
  initial-value: #974DE0
  inherits: false

.subscribe_btn
  width: 100%
  border-radius: 8px
  font-size: 20px
  margin-top: auto

  transition: --gradient-color-1 .2s, --gradient-color-2 .3s
  background: linear-gradient(270.09deg, var(--gradient-color-1), var(--gradient-color-2))
  --gradient-color-1: #974DE0
  --gradient-color-2: #974DE0

  &:hover
    background: linear-gradient(270.09deg, var(--gradient-color-1), var(--gradient-color-2))
    --gradient-color-1: #F85B2E
    --gradient-color-2: #B688E4
    opacity: 1

.price_wrapper
  display: flex
  justify-content: center
  flex-direction: row

.crossedPrice
  display: flex
  color: rgba(0, 0, 0, .4)
  height: 18px
  font-size: 28px
  font-weight: 500
  margin: 4px 0 0 3px
  gap: 1px
  flex-direction: row
  justify-content: flex-start


.ruble_wrapper_gray
  width:18px