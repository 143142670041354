.buyButton
  border: 1px solid #F2F2F2
  box-sizing: border-box
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15)
  border-radius: 10px
  padding: 13px 18px
  background-color: rgb(255 255 255)
  color: rgb(0 0 0)
  font-size: 20px
  width: 290px
  z-index: 1
  &_fixed
    position: fixed
    bottom: 40
    left: 50%
    transform: translateX(-50%)
  &_hide
    display: none
  &__text
    font-size: 10px
    line-height: 15px
    color: #828282