@import '~/styles'

.subscription_form
  max-width: 970px
  margin: 0 auto

  display: flex
  flex-direction: column
  gap: 24px

  .items_container
    display: flex
    gap: 16px
    justify-content: center

    +max-screen($main-xs)
      flex-direction: column

  .caption_container
    text-align: center

    a
      color: $purple-dark

    .patreon_caption
      margin-bottom: 2px
      line-height: 100%

      +max-screen($main-xs)
        font-weight: 700
        margin-bottom: 4px
