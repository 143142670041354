@import '~/styles'

.page
  display: flex
  position: relative
  margin-right: auto
  background: white

  &_content
    position: relative
    z-index: 5
    background-color: $white
    min-height: calc(100vh - 190px)
      min-height: calc(100vh - 80px)
    +max-screen($main-xs)
      min-height: calc(100vh - 57px)

  &.dark
    color: $white
    background-color: #181818
    .page_content
      background-color: #181818
    .page_header
      &.border
        +max-screen($screen-md)
          background-color: #181818
          border-bottom-color: #373739
          color: $white

.content
  width: calc(100% - #{$menu-w})

  display: flex
  flex-direction: column

  +max-screen($screen-md)
    width: 100%
    margin-left: 0

  .inner_content
    min-height: 100vh
    flex-grow: 1
    overflow: hidden

    +max-screen($screen-md)
      margin-top: $header-height

.login
  display: flex
  justify-content: start
  align-items: center
  gap: 7px

  >p
    transform: translateY(1px)
    font-size: 20px

  .login_label
    text-transform: uppercase

  &_username
    width: 100%
    text-overflow: ellipsis
    overflow: hidden
    font-size: 20px

  .badge_text
    font-size: 12px

  svg
    flex-shrink: 0
    fill: $purple-dark

  @media (hover: hover) and (pointer: fine)
    &:hover
      color: $purple-dark

  &_text
    display: flex
    align-items: center
    cursor: pointer
    text-transform: inherit
  &_user
    max-width: 100%
    height: 19px
    margin-left: 10px
    padding: 0 7px
    line-height: 16px
    font-size: 12px
    color: #000000
    border: 1px solid $orange
    border-radius: 2px
    background-color: $orange
    text-overflow: ellipsis
    white-space: pre
    overflow: hidden
    box-shadow: 0 0 5px 0px rgba(0,0,0,0.2)
  &_icon
    margin-left: 10px
    fill: currentColor
  &_skeleton
    height: 18px
    background-color: #eee
    background: linear-gradient(100deg,hsla(0,0%,100%,0) 40%,lab(64.24 55.84 52.7 / 0.1) 50%,hsla(0,0%,100%,0) 60%) #fff
    background-size: 200% 100%
    background-position-x: 180%
    animation: loading 2s ease-in-out infinite

@keyframes loading
  to
    background-position-x: -20%