@import '~/styles/'

.recommendation_section
  max-width: $content-max-w
  margin: 0 auto
  padding: 0 $content-padding-x
  margin-bottom: 15px

  +max-screen($screen-md)
    margin-bottom: 32px

  &__title
    margin-top: 54px
    margin-bottom: 24px

    +max-screen($screen-lg-down)
      margin-top: 44px
      margin-bottom: 12px

  .guides_container
    > div
      padding: 32px 0
      border-top: 1px solid $white-8
      overflow: hidden

      +max-screen($screen-lg-down)
        padding: 20px 0
