@import '~/styles'

.subscription_feature_item
  display: flex
  padding: 32px 0

  +max-screen($screen-sm)
    flex-direction: column
    padding: 24px 0

  &__with_separator
    border-bottom: 1px solid $border-light

  .first_column
    width: 40%
    color: $purple-dark

    +max-screen($screen-sm)
      width: 100%
      margin-bottom: 14px

    .title
      margin-bottom: 8px

    > *
      line-height: 100%

  .second_column
    width: 60%

    +max-screen($screen-sm)
      width: 100%
