@import '~/styles'

.subscription_header
  padding: 52px 80px
  background: $neutral-70
  margin-bottom: 54px

  +max-screen($screen-lg)
    padding: 32px 16px

  +max-screen($screen-lg-down)
    margin-bottom: 32px

  .titles_container
    max-width: 778px
    margin: 0 auto 36px auto
    text-align: center

    +max-screen($screen-lg-down)
      margin-bottom: 32px

  .main_title
    margin-bottom: 8px

    +max-screen($screen-lg-down)
      font-size: 36px
      line-height: 100%
      margin-bottom: 4px

  .subtitle
    font-weight: 400
    font-size: 24px
    line-height: 33.6px

    +max-screen($screen-lg-down)
      font-size: 22px
      line-height: 30.8px

@property --gradient-color-1
  syntax: '<color>'
  initial-value: $white
  inherits: false

@property --gradient-color-2
  syntax: '<color>'
  initial-value: $white
  inherits: false

.gift_button
  background: linear-gradient(270.09deg, var(--gradient-color-1), var(--gradient-color-2))

  @media only screen and (max-device-width: $screen-lg-down)
    background: white

  gap: 16px
  height: 76px
  border-radius: 8px
  transition: --gradient-color-1 .2s, --gradient-color-2 .2s

  width: 970px
  max-width: 100%
  margin: 36px auto 0 auto

  +max-screen($screen-lg-down)
    margin-top: 32px

  +max-screen($screen-sm-down)
    padding: 0 12px
    justify-content: space-between

  &:hover
    background: linear-gradient(270.09deg, var(--gradient-color-1), var(--gradient-color-2))
    --gradient-color-1: #F85B2E
    --gradient-color-2: #B688E4
    opacity: 1

    .gift_button__label
      color: $white
      transition: .2s color

    svg
      color: $white
      transition: .2s color

  &__label
    text-align: left
    line-height: 22px
    font-weight: 700
    color: $orange

  svg
    width: 36px
    height: 36px
    transform: rotate(15deg)
    color: $orange
