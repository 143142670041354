@import '~/styles/'

.topic_badge
  height: 32px
  background-color: $white
  padding: 0 10px
  border-radius: 20px
  transition: .2s opacity

  display: flex
  align-items: center
  gap: 4px
  cursor: pointer

  &:hover
    opacity: 0.7

  &__dot
    width: 20px
    height: 20px
    position: relative

    &::after
      content: ''

      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

      display: block
      width: 11px
      height: 11px
      background: $green
      border-radius: 50%

    &_is_topic
      &::after
        background: $orange
  
    &_is_tag
      &::after
        background: $purple-dark

  > p
    font-size: 14px
    line-height: 100%
