@import '~/styles'

@mixin position-line
  content: ""
  position: absolute
  left: 0
  bottom: 0
  width: 85%
  transform: rotate(-12deg)

.reportPrice
  position: relative
  font-size: 25px
  line-height: 28px
  +max-screen($screen-sm-down)
    font-size: 22.5px
  &__type
    &_free
      color: $orange
  &__priceContainer
    display: inline-block
    margin-left: 0.3em
    +max-screen($screen-sm-down)
      display: block
      margin-left: 0
  &__price
    color: $orange
    +max-screen($screen-sm-down)
      color: inherit
  &__discount
    position: absolute
    margin-left: 5px
    font-size: 15px
    line-height: 74%
    overflow: hidden
    color: $gray
    &::after
        @include position-line
        z-index: +1
        top: calc( 41% + 1px )
        border-top: 2px solid
        border-color: inherit
    +max-screen($screen-md)
      top: 12%
    +max-screen($screen-sm-down)
      top: 28px
      font-size: inherit
      line-height: 28px
