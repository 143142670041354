@import '~/styles'


.mobile
	position: relative
	margin-top: $padd
	padding-top: 0
	display: none
	.link
		width: min-width
		font-size: 20px
		margin-right: 24px
		&:last-child
			margin-right: 0
	+max-screen($screen-md)
		display: flex
