.title
  font-size: 25px
  line-height: 30px
  font-weight: bold
  margin-bottom: 18px

.wrapper
  display: flex
  flex-direction: column
  gap: 10px

.item
  text-decoration: underline
  text-align: left
  &:hover
    text-decoration: none