@import '~/styles'

.tag_old
  padding: 10px
  border-radius: 2px
  font-size: 25px
  letter-spacing: 1px
  white-space: nowrap
  text-transform: uppercase
  transition: 0.3s
  &:hover
    background-color: $orange
    color: $white
  +max-screen($screen-sm)
    padding: 6.7px
    letter-spacing: 0.67px
    font-size: 16.7px
  &__color
    &_black
      background-color: $black
      color: $white
    &_red
      background-color: rgba($tag-red, 0.3)
      color: $tag-red
    &_blue
      background-color: rgba($tag-blue, 0.3)
      color: $tag-blue
    &_yellow
      background-color: rgba($tag-yellow, 0.3)
      color: $tag-yellow
    &_green
      background-color: rgba($tag-green, 0.3)
      color: $tag-green
    &_purple
      background-color: rgba($purple-dark, 0.2)
      color: $purple-dark


.tag
  width: min-content
  height: 20px
  padding: 0 8px
  border-radius: 20px
  transition: .3s background, .3s color

  display: flex
  align-items: center

  font-size: 10px
  line-height: 10px
  letter-spacing: 0.6px
  white-space: nowrap
  text-transform: uppercase
  
  background-color: $gray-light
  color: $black

  &_size_lg
    +min-screen($screen-lg-down)
      height: 32px
      font-size: 14px
      padding: 8px 10px
  &:hover
      background-color: $orange
      color: $white

