@import '~/styles/'

.subscription_form_section
  background: $neutral-70
  border-radius: 4px
  padding: 52px 80px
  text-align: center

  display: flex
  flex-direction: column
  gap: 24px
  align-items: center

  +max-screen($screen-lg-down)
    gap: 20px
    padding: 32px 16px

  &__btn_has_subscription
    background: none
    border: 1px solid $purple-dark
    color: $purple-dark
    height: 48px
    margin-bottom: 12px

    &:hover
      background: $purple-dark
      color: $white

    +max-screen($screen-lg-down)
      height: 40px

    > p
      transition: .2s color
      font-size: 20px
