@mixin no-scrollbar
	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar,
	&::-webkit-scrollbar-thumb,
	&::-moz-scrollbar
		-webkit-appearance: none !important
		width: 0 !important
		height: 0 !important
		background: transparent !important
		visibility: hidden !important
		opacity: 0 !important
		cursor: default !important
		display: none
		scrollbar-color: transparent transparent

	scrollbar-width: none

%fancy-scrollbar
	overflow-y: auto

	scrollbar-width: thin
	scrollbar-color: $scrollbar-thumb $scrollbar-track

	::-webkit-scrollbar
		width: 10px

	::-webkit-scrollbar-track
		background: $scrollbar-track

	::-webkit-scrollbar-thumb
		background: $scrollbar-thumb

	::-webkit-scrollbar-thumb:hover
		background: $scrollbar-thumb-hover

	::-moz-scrollbar-button:decrement,
	::-moz-scrollbar-button:increment,
	::-webkit-scrollbar-button:decrement,
	::-webkit-scrollbar-button:increment
		width: 0
		height: 0
		display: none

	::-moz-scrollbar-button, ::-webkit-scrollbar-button
		width: 0
		height: 0
		display: none
