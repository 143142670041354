@import '~/styles'

.modal
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(0, 0, 0, .7)
  &__content
    outline: none
    position: fixed
    top: 50%
    left: 50%
    right: auto
    bottom: auto
    margin-right: -50%
    transform: translate(-50%, -50%)
    min-width: 320px
  &__close
    position: absolute
    top: 10px
    right: 10px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    width: 21px
    height: 21px
    background-color: rgb(0, 0, 0)

.form
  background-color: rgb(255, 255, 255)
  border-radius: 10px
  display: flex
  flex-direction: column
  align-items: center
  width: 500px

  +max-screen($screen-sm-down)
    max-width: 320px
    padding: 0

  &__content
    padding: 32px 37px 0

  box-sizing: border-box
  &__title
    max-width: 409px
    text-align: center
    font-size: 40px
    line-height: 45px
    font-weight: 500
    +max-screen($screen-sm-down)
      font-size: 25px
      line-height: normal
    &_ok
      display: flex
      flex-direction: column
  &__subTitle
    font-size: 25px
    margin-top: 10px
    font-weight: 400
    text-align: center
    +max-screen($screen-sm-down)
      font-size: 18px
      line-height: normal
  &__submit
    margin-top: 41px
    border-radius: 4px
    height: 59px
    width: 110%
    box-shadow: 0px 10px 15px rgba(0, 0, 0, .25)
    background-color: rgb(255, 255, 255)
    display: flex
    font-size: 20px
    +max-screen($screen-sm-down)
      margin-top: 20px
      width: 110%
    &_ok
      width: 165px
      justify-content: center
      align-items: center
      margin-bottom: -21.7px
    &_red
      background-color: $orange
      color: $white
  &__input
    width: 100%
    box-sizing: border-box
    padding: 0 25px
    &::placeholder
      color: rgb(0, 0, 0)
  &__modal_input
    padding: 7px 0
    border-radius: 4px
    line-height: 35px
    text-align: center
    width: 100%
    border: 2px solid black
    margin-top: 30px
  &__button_block
    display: flex
    justify-content: space-between
    button
      margin-left: 10px
      margin-right: 10px
  &__button
    width: 45.31%
    text-align: center
    border-left: solid rgb(226, 226, 226) 1px

.payment_form_wrapper
  overflow-y: auto
  max-height: 95vh
  min-width: 320px

.payment_form
  padding-top: 40px
  margin: auto