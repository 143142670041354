@import '~/styles'

.form
	display: grid
	gap: 28px
	padding: 23px
	border-radius: 4px
	background-color: rgba($white, 0.3)
	max-height: 388px
	margin-bottom: 50px
	+max-screen($screen-sm-down)
		max-height: 428px
		margin: 0 -20px 57px

.box
	&Radio
		display: grid
		grid-auto-flow: column
		grid-auto-columns: 1fr
		gap: 2px
		background-color: rgba($black, 0.3)
		padding: 3px
		border-radius: 4px
		+max-screen($screen-sm-down)
			grid-template-columns: 1fr 1fr
			grid-auto-flow: row

	&Text
		display: flex
		background-color: transparent
		padding: 0
		gap: 14px
		+max-screen($screen-sm-down)
			gap: 8px
		& > *
			padding: 7px
			background-color: rgba($black, 0.3)
	&Email
		display: grid
		justify-items: center
	&Subscription
		display: grid
		justify-items: left
		padding: 0 40px
		p
			margin-top: 10px
			color: $white
		span
			color: #e2e2e2
		@media (max-width: 380px)
			padding: 0

.label
	text-align: center
	width: 100%
	cursor: pointer

.radio
	position: absolute

.inputRadio,
.inputText
	border-radius: 4px
	line-height: 35px
	text-align: center
	width: 100%
	+max-screen($screen-sm-down)
		font-size: 15px
		line-height: 35px
	&::placeholder
		color: rgba($white, 0.6)
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button
		display: none
		appearance: none

.input
	&Radio
		color: rgba($white, 0.6)
		padding: 4px 0
		&:hover
			background-color: $white
			color: $black
			&::placeholder
				color: rgba($black, 0.6)
	&Text
		color: $white
		padding: 7px 0
		background-color: rgba($black, 0.3)
		&_invalid
			background-color: rgba(red, 0.5)
			&::placeholder
				color: $white

.radio:checked + .inputRadio
	background-color: $white
	color: $black
	&::placeholder
		color: rgba($black, 0.6)

.error
	font-size: 10px
	line-height: 12px
	margin-top: 2px

.submit	
	background-color: $white
	color: $black
	width: 204px
	justify-self: center
	transform: translateY(27px)
	box-shadow: 0px 13px 54px rgba(101, 80, 163, 0.7)
	transition: 0.2s all
	&:hover
		background-color: $orange
		color: $white		
		
	+max-screen($screen-sm-down)
		font-size: 20px
		line-height: 35px
		width: 100%	

.submit_revoked
	display: flex
	flex-direction: column
	background-color: $white
	color: $black
	width: 70%
	justify-self: center
	transform: translateY(27px)
	box-shadow: 0px 13px 54px rgba(101, 80, 163, 0.7)
	transition: 0.2s all
	&:hover
		background-color: $orange
		color: $white
		>.submit_subtitle
			color: $white
		
	+max-screen($screen-sm-down)
		font-size: 20px
		line-height: 35px
		width: 100%

	@media (max-width: 640px)
		width: 70vw

	@media (max-width: 576px)
		.submit_subtitle
			font-size: 12px
	@media (max-width: 350px)	
		.submit_subtitle
			font-size: 10px

.toAnnual
	margin: 14px auto -54px auto
	width: 100%
	display: flex
	flex-direction: column
	align-items: center

.submit_title
	width: 100%
	font-size: 24px
	white-space: nowrap
	margin-bottom: -10px
 
.submit_subtitle
	width: 100%
	font-size: 14px
	color: $gray
	
