@import '~/styles'

.post
    display: flex
    width: 100%
    max-width: 800px
    background: #373739
    border-radius: 4px
    margin-bottom: 20px
    transition: 0.3s box-shadow
    &:hover
        box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.75)
    +max-screen(680px)
        flex-direction: column

.content
    width: 100%
    padding: 20px
    +max-screen(680px)
        padding: 10px

.top
    padding-bottom: 20px
    +max-screen($screen-lg-down)
        padding-bottom: 18px

.tag
    width: 100%
    font-size: 10px
    text-transform: uppercase
    margin-bottom: 23px
    +max-screen(680px)
        margin-bottom: 15px

.title
    width: 100%
    font-size: 23px
    line-height: 25px
    color: $white
    transition: 0.3s color
    cursor: pointer
    &:hover
        color: $orange
    +max-screen(680px)
        line-height: 23px

.imageWrap
    @extend %RICHTEXT-IMAGE
    display: flex
    align-items: center
    justify-content: center
    width: 370px
    min-width: 370px
    height: 201px
    overflow: hidden
    cursor: pointer
    +min-screen(680px)
        figure, picture
            border-bottom-left-radius: 0 !important
            border-top-left-radius: 0 !important
    +max-screen(680px)
        width: 100%
        min-width: 100%
        height: auto
        figure, picture
            border-top-left-radius: 0 !important
            border-top-right-radius: 0 !important

.image
    width: 100%
    +max-screen(680px)
        margin-top: 10px !important
