@import '~/styles'

.container
	background-image: url("~/images/donate/bg-gradient.png")
	background-size: cover
	background-repeat: no-repeat
	+max-screen($screen-sm-down)
		background-image: url("~/images/donate/bg-gradient-mobile.png")

.wrap
	// @import './../post/typog.sass'
	max-width: 690px
	margin: 0 auto
	word-break: break-word
	font-size: 20px
	line-height: 25px
	padding: 20px
	color: $white
	+max-screen($screen-sm-down)
		padding: 15px 20px

.logo
	width: 225px
	filter: invert(1)
	margin-bottom: 55px
	+max-screen($screen-sm-down)
		margin-bottom: 20px

.title
	font-size: 40px
	line-height: 38px
	&Container
		display: grid
		gap: 10px
		margin-bottom: 57px
	&Box
		display: flex
		align-items: center
		justify-content: space-between
		gap: 10px
		+max-screen($screen-sm-down)
			flex-direction: column
			align-items: inherit
	&Arrows
		display: grid
		height: 100%
		align-content: space-around
	&Mobile
		margin-bottom: 57px
		width: 100%
		border-top: 1px solid $white
		padding-top: 20px
	&Notice
		margin-right: 130px
		+max-screen($screen-sm-down)
			margin: 0

.sideBar
	display: flex
	flex-direction: column
	gap: 5px
	> * 
		text-decoration: underline
		text-align: left
		&:hover
			text-decoration: none
	+max-screen($screen-sm-down)
			margin: 20px 0

.description
	font-size: 20px
	line-height: 25px
	padding-right: 40px
	margin-bottom: 20px
	+max-screen($screen-sm-down)
		margin-bottom: 58px
		padding-right: 0

.subtitle
	font-size: 25px
	line-height: 30px
	font-weight: bold
	// margin-bottom: 18px
		
.changePassword
	text-decoration: underline
	margin-bottom: 18px

.toSubs
	&_wrapper
		display: flex
		justify-content: center
		width: 100%
		transition: all 0.3s
		margin: 14px auto 0px auto
	&_button
		display: flex
		justify-content: center
		align-items: center
		text-align: center
		width: 81%
		height: 74px
		background: $white
		border-radius: 4px
		color: #000
		font-size: 24px
		line-height: 24px
		+max-screen($screen-sm-down)
			width: 70vw
			font-size: 20px
			line-height: 20px
		&:hover
			transform: scale(1.01)


.promise
	&Container
		display: grid
		grid-auto-flow: column
		grid-template-rows: 240px
		gap: 14px
		padding-bottom: 65px
		overflow: auto
		+max-screen($screen-sm-down)
			grid-auto-columns: 263px
			gap: 10px
			margin: 0 -20px
			padding-left: 20px
			padding-bottom: 43px
			&::after
				content: ''
				width: 1px
	&Box
		display: grid
		grid-template-rows: 1fr 1fr
		gap: 10px
		background: rgba($white, 0.3)
		border-radius: 4px
		padding: 0 20px
		&:last-child > img
			padding-top: 10px
	&Text
		font-size: 17px
		line-height: 22px
	&Image
		justify-self: center

.offer
	font-size: 20px
	line-height: 26px
	margin: 0 25px 40px
	+max-screen($screen-sm-down)
		font-size: 17px
		line-height: 18px
		margin-left: 15px
		margin-right: 15px
	&Link
		text-decoration: underline

.qa
	&Container
		+max-screen($screen-sm-down)
			margin: 0 -10px
	&Wrapper
		display: block
		border-radius: 4px
		padding: 20px 0 0 11px
		&:hover,
		&:focus-within
			background: rgba($white, 0.3)
	&Box
		display: grid
		grid-auto-flow: column
		align-items: center
		justify-content: space-between
		cursor: pointer
		padding-right: 30px
		transition: 0.2s all
		+max-screen($screen-sm-down)
			padding-right: 0
		& > svg
			transition: 0.2s all
			width: 22px
			+max-screen($screen-sm-down)
				width: 32px
	&Text
		font-size: 20px
		line-height: 26px
		font-weight: 500
		+max-screen($screen-sm-down)
			font-size: 17px
			line-height: 18px
	&Hidden
		font-size: 17px
		line-height: 22px
		padding: 22px 12px 0 3px
		transition: 0.2s all
		overflow: hidden
		max-height: 0
	&Input
		position: absolute
		&:checked
			& ~ .qaHidden
				padding-bottom: 15px
				max-height: 200px
				+max-screen($screen-sm-down)
					max-height: 450px
			& ~ .qaBox > svg
				transform: rotate(180deg)
