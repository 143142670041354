:global(.extended-link_active)
  color: #FFF !important

  :global(.word)
    &::before
      background: black !important
      filter: blur(0px) !important

:global(.extended-link)
  background: none
  color: #000

  padding: 1px 3px
  cursor: pointer
  position: relative
  transition: .1s all

  &:hover
    color: #FFF !important

    :global(.word)
      &::before
        background: black
        filter: blur(0px)

  > :global(.word)
    position: relative
    display: inline-block

    &::before
      content: ''
      position: absolute
      top: 0
      left: -5px
      right: -5px
      bottom: 0
      background: #e9dbf7
      transition: .2s all

      display: inline-block
      border-radius: 4px
      height: 100%
      z-index: 0

      will-change: filter
      filter: blur(4px)

    > :global(.word)
      position: relative
      z-index: 1
