@import '~/styles/'

.header_section
  background-color: $neutral-70
  padding-top: 60px

  +max-screen($screen-lg-down)
    padding: 32px 16px 0 16px

  &__inner_container
    max-width: 778px
    text-align: center
    margin: 0 auto

    display: flex
    flex-direction: column
    align-items: center
    gap: 24px

    +max-screen($screen-lg-down)
      gap: 20px

.guide_badge
  background: $green
  color: $white
  height: 32px
  width: fit-content
  padding: 0 10px
  border-radius: 20px
  transition: .2s opacity

  display: flex
  align-items: center

  &:hover
    opacity: 0.8

  > p
    font-size: 14px
    line-height: 100%

.badges_container
  display: flex
  gap: 8px

.published_date_label
  color: $black-40

.image
  margin: 0
  transform: translateY(9px)

  img
    max-width: 778px

.authors_section
  max-width: calc(778px + 2 * $content-padding-x)
  margin: 54px auto 0 auto
  padding: 0 $content-padding-x

  +max-screen($screen-lg-down)
    margin-top: 28px

  &__title
    margin-bottom: 13px

    +max-screen($screen-lg-down)
      margin-bottom: 14px

  &__authors_container
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 14px

    +max-screen($screen-sm-down)
      grid-template-columns: 1fr
      gap: 12px
