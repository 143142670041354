@import '~/styles/'

.badge
  background-color: $gray-light
  color: $black
  padding: 0 8px
  border-radius: 20px
  height: 20px

  display: flex
  justify-content: center
  align-items: center

  > *
    line-height: 100%

  &__primary
    background-color: $purple-dark
    color: $white
