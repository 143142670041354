@import '~/styles/'

.guides_header
  background-color: $neutral-70
  padding: 52px 80px
  text-align: center
  margin-bottom: 22px

  display: flex
  align-items: center
  flex-direction: column
  gap: 24px

  +max-screen($screen-lg-down)
    gap: 20px
    padding: 32px 16px

  &__title
    max-width: 778px

  &__cards_container
    display: flex
    gap: 2px
    max-width: 970px
    width: 100%

    +max-screen($main-md)
      flex-direction: column

  &__button
    width: 100%
    max-width: 970px

    > button
      width: 100%

    .button_label
      font-size: 20px
