@import './styles'

*, *:before, *:after
	box-sizing: border-box

BODY
	margin: 0
	padding: 0
	font-size: 20px
	font-family: 'cofo'
	line-height: 1.5
	background: $white
	overflow-x: hidden
	min-width: 320px

H1, H2, H3, H4, H5, H6, P, A, OL, UL, LI,
INPUT, TEXTAREA, SELECT, BUTTON
	font-family: inherit
	font-weight: inherit
	font-style: inherit
	font-size: 100%
	line-height: normal
	vertical-align: baseline
	text-decoration: none
	color: inherit
	margin: 0
	padding: 0

INPUT, TEXTAREA, SELECT, BUTTON
	-webkit-appearance: none
	outline: none
	border: none
	border-radius: 0
	background-color: transparent
	&:focus
		outline: none

UL, OL
	list-style: none

BUTTON
	cursor: pointer

[class="hidden"]
	opacity: 0
	visibility: hidden
	pointer-events: none

+max-screen($screen-md)
	[class="mobiNoneScroll"]
		overflow: hidden

div[class~="podcast"]
	margin-top: 20px
	div
			font-size: 12px
