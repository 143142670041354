@import '~/styles'

.recommend
    max-width: $content-max-w
    margin: 100px auto 0 auto
    padding: 0 $content-padding-x 0 $content-padding-x
    border-top: 1px solid #000
    color: #000
    +max-screen(680px)
        margin-top: 46px

.title
    width: 100%
    font-size: 25px
    line-height: 35px
    margin-top: 0.5em
    margin-bottom: 0.4em
    +max-screen(680px)
        font-size: 30px
        line-height: 30px

.wrap
    display: flex
    overflow: auto
    justify-content: space-between

