@import '~/styles'

.container
  display: flex
  align-content: flex-start
  width: 100%

  & + .container
    border-top: none
  &:first-child
    border-top: none
  &:last-child
    border-bottom: none
  &:hover
    .title
      color: $orange

  +max-screen($main-xs)
    flex-direction: column
  &_preview,
  &_preview_longread
    .title
      font-family: 'cofo-bold'
      letter-spacing: -1px
      font-size: 30px
      line-height: 33px
    +max-screen($screen-lg-down)
      .title
        font-family: 'cofo-bold'
        letter-spacing: -1px
        font-size: 26px // 23px
        line-height: 26px // 25px
  &_preview_square
    .title
      font-family: 'cofo-bold'
      letter-spacing: -2px
      font-size: 44px // 55px
      line-height: 44px // 50px
      letter-spacing: -2px
    .content
      margin-bottom: 0
    .imageWrap
      display: flex
    +max-screen($screen-lg-down)
      .title
        font-size: 35px
        line-height: 35px
        letter-spacing: -1px
    +max-screen($screen-md-down)
      .title
        font-family: 'cofo-bold'
        font-size: 32px
        line-height: 32px
        letter-spacing: -1px
    +max-screen($screen-sm-down)
      .imageWrap
        margin-top: 10px
      .title
        font-family: 'cofo-bold'
        font-size: 26px // 28px
        line-height: 26px // 28px
        letter-spacing: -1px
      .description
        display: none
        &_mob
          display: block

.content
  flex: 1
  padding-right: 32px

  display: flex
  flex-direction: column
  justify-content: center

  +max-screen($main-xs)
    padding-right: 0
    margin-bottom: 20px

.top
  display: flex
  flex-wrap: wrap
  gap: 4px
  width: 100%
  margin-bottom: 12px
  width: fit-content

  &:hover
    & + .title
      color: black

.title
  display: block
  word-break: break-word
  &:hover
    color: $orange

.description
  display: block
  margin-top: 8px

  +max-screen($screen-lg-down)
    margin-top: 4px
  &_mob
    display: none
    flex: 1
    margin-right: 10px

.imageWrap
  @extend %RICHTEXT-IMAGE

  FIGURE
    cursor: pointer
    max-width: 580px

.place
  &-preview,
  &-preview_super
    width: 700px
    +max-screen($screen-lg-down)
      width: 405px
    +max-screen($screen-md-down)
      width: 350px
    +max-screen($screen-sm-down)
      width: 100%
  &-preview_square,
  &-preview_longread
    width: 280px
    height: 280px
    +max-screen($screen-lg-down)
      width: 220px
      height: 220px
    +max-screen($screen-md-down)
      width: 190px
      height: 190px
    +max-screen($screen-sm-down)
      width: 95px
      height: 95px
