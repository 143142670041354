@import '~/styles/'

.guide_content_item
  display: flex
  justify-content: space-between
  align-items: center
  gap: 16px
  cursor: pointer
  transition: .2s opacity

  &:hover
    opacity: 0.8

  &__text
    opacity: 0.5
    transition: .2s opacity, .1s color

    &_is_active
      opacity: 1

  &__point
    width: 16px
    height: 16px
    background-color: $white-8
    border-radius: 50%
    flex-shrink: 0
    transition: .1s background-color

    &_is_active
      background-color: $green
