@import '../../../styles'

.container
    position: relative
    display: flex
    flex-direction: column
    width: 100%
    padding: 20px
    font-family: 'cofo'
    transition: 0.3s opacity
    +max-screen($screen-md)
        min-width: 100vw
    +min-screen($screen-md)
        &.hide
            &-false
                opacity: 1
            &-true
                opacity: 0
                pointer-events: none

.wrap
    z-index: 2

.title
    font-size: 18px
    line-height: 35px
    letter-spacing: 0.5px
    font-weight: 500
    text-transform: uppercase
    &:hover
      color: $orange

.desc
    white-space: pre-wrap
    margin-top: 5px
    margin-bottom: 14px
    font-size: 15px
    line-height: 18px
    color: #727272
    a
        color: $purple
        border-bottom: 1px solid currentColor
        &:hover
            border-bottom-color: transparent
    +max-screen($screen-md)
        margin-top: -1px
        margin-bottom: 11px

.button
    display: flex
    align-items: center
    justify-content: center
    max-width: 100%
    height: 55px
    font-size: 20px
    line-height: 18px
    padding: 5px 10px
    border-radius: 4px
    background-color: #ECECEC
    color: #000
    transition: 0.3s
    cursor: pointer
    &:hover
        background-color: $orange
        color: $white
    +max-screen($screen-md)
        display: none

.form
    FORM
        display: flex
        width: 100%
        height: 57px
        color: #000
    INPUT
        width: 100%
        height: 100%
        background-color: $white
        border-radius: 4px 0 0 4px
        padding: 0 15px
    BUTTON
        display: flex
        align-items: center
        justify-content: center
        width: 56px
        height: 100%
        min-width: 56px
        margin-left: 1px
        background-color: $white
        border-radius: 0px 4px 4px 0px
        svg
            width: 16px
            height: 16px
            fill: currentColor

    +min-screen($screen-md)
        display: none

.background
    position: absolute
    bottom: 2px
    left: -55px
