@import '~/styles'

.footer_email_subscription
  position: relative
  margin: 40px 80px 0 80px

  display: flex

  +max-screen($screen-sm)
    margin: 32px 16px 0 16px

    flex-direction: column
    gap: 8px

  &_input
    height: 84px
    width: 614px
    background: $white
    padding: 29px 24px
    border-radius: 4px 0 0 4px
    color: $black

    font-weight: 500
    font-size: 24px

    &::placeholder
      color: $black-40

    &:disabled
      opacity: 1

    +max-screen($screen-sm)
      width: 100%
      border-radius: 4px
      height: 60px

  &_button
    background: $black
    color: $white
    padding: 29px 40px
    height: 84px
    border-radius: 0 4px 4px 0
    display: flex
    justify-content: center
    align-items: center

    +max-screen($screen-sm)
      border-radius: 4px
      height: 60px
