@import '~/styles'

.subscription_features
  .features_title
    margin-bottom: 32px

    +max-screen($screen-lg-down)
      margin-bottom: 0

  .slider
    margin-bottom: 54px

    +max-screen($screen-lg-down)
      margin-top: 16px
      margin-bottom: 32px

  .feature_item__last
    margin-bottom: 54px

    +max-screen($screen-lg-down)
      margin-bottom: 32px

  .separator
    height: 1px
    background: $white-8

    +max-screen($screen-lg-down)
      display: none

.feature_item_single
  +max-screen($screen-sm)
    padding: 8px 0
