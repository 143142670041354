@import '~/styles'

.post
    display: flex
    flex-direction: column
    min-width: 250px
    margin-right: 22px
    margin-bottom: 20px
    flex: 1
    &:last-child
        margin-right: 0
    +max-screen(680px)
        width: 250px
        min-width: 250px

.tag
    width: 100%
    font-size: 10px
    text-transform: uppercase
    margin-bottom: 23px
    +max-screen(680px)
        margin-bottom: 15px

.title
    width: 100%
    font-size: 22px
    line-height: 25px
    word-break: break-word
    transition: 0.3s color
    cursor: pointer
    &:hover
        color: $orange
    +max-screen(680px)
        font-size: 23px
        line-height: 27px

.image
    @extend %RICHTEXT-IMAGE
    width: 100%
    cursor: pointer
    margin-bottom: 20px
    +max-screen(680px)
        figure, picture
            border-radius: 0 !important

.topic
    margin-bottom: 5px
