@import '~/styles'

.topic
    display: block
    padding-bottom: 20px
    text-transform: uppercase
    font-size: 10px
    line-height: 12px
    letter-spacing: 0.4px
    width: fit-content
.title
    display: block
    font-size: 20px
    line-height: 100%
    margin-bottom: 40px
    font-weight: 500
    &:hover
        color: $orange
    +max-screen($screen-md-down)
        font-size: 17px
        margin-bottom: 20px
