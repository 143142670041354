@import '~/styles'
@import '../super/PageHeaderSuper'

$imgH: 550px
$paddingTopHeader: 60px
$reportHeaderH: 560px

.pageReportHeader
    position: relative

    margin-right: auto
    background-color: black
    width: 100%
    height: $reportHeaderH
    margin-bottom: -235px
    margin-bottom: 0
    color: $white
    z-index: 1000
    &__noise
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
    +max-screen(1280px)
        margin-bottom: -19vw
        margin-bottom: 0
    &__content
        position: relative
        padding: $paddingTopHeader 0 63px 0
        margin-left: 40px
        z-index: 20px
        display: flex
        +max-screen(1290px)
            margin-left: 40px
        +max-screen($screen-md)
            margin-left: 0
    &__img
        min-width: 390px
        height: $imgH
        margin: 0 15px
        border-radius: 4px
        box-shadow: (0px 24px 34px rgba(0, 0, 0, 0.25))
    &__body
        width: 60%
        display: flex
        flex-direction: column
        align-items: self-start
        max-width: 582px
    &__title
        height: 200px
        font-weight: 500
        font-size: 60px
        line-height: 55px
        margin-top: 19px
    &__price
        margin-top: 30px
    &__count
        font-size: 39.14px
        line-height: 46.97px
    &__discount
        position: absolute
        font-size: 20.61px
        line-height: 25px
        margin-left: 7px
    &__button
        margin-top: 20px
        background-color: rgb(255, 255, 255)
        border-radius: 10px
        font-size: 20px
        line-height: 35px
        color: rgb(0, 0, 0)
        width: 211.34px
        height: 58.59px
    &__date
        margin-top: 19px
        font-size: 15px
        line-height: 18px
        width: 278px