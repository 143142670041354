@import '~/styles'

.profile
  display: flex
  align-items: center
  gap: 8px

  &__title
    display: flex
    align-items: center
    gap: 4px
    cursor: pointer

    &:hover
      color: $purple-dark

.reminder_badge_content
  display: flex
  align-items: center
  gap: 2px
  font-weight: 700

  > svg
    width: 12px
    height: 12px

.badge_text
  font-size: 12px
