@import '~/styles'

.socPreview
	padding-top: 20px
	padding-bottom: 20px
	overflow: hidden
	+max-screen($screen-md)
		display: none
		& + div // грязный хак
			border-top: none

.wrap
	position: relative
	display: flex
	max-width: 1162px
	width: calc(100% - 10px)
	height: 232px
	margin-left: auto
	margin-right: auto
	+max-screen($screen-lg-down)
		.fb, .yt, .tg
			font-size: 70px

.title
	position: absolute
	left: 0
	bottom: 200px
	font-size: 30px
	line-height: 33px

.item
	position: relative
	font-size: 75px
	line-height: 50px
	svg
		width: 100%
		position: absolute
		transition: 0.3s
		z-index: 1
	span
		position: absolute
		z-index: 3

.fb
	width: 60%
	height: 189px
	padding-left: 40px
	display: flex
	align-items: center
	position: absolute
	left: 0
	bottom: 0
	z-index: 1
	span
		left: 60px
		transition: 0.3s
		+max-screen(1360px)
			margin-top: 20px
	&:hover
		svg
			transition: 0.3s
			transform: rotate(-15deg)

.yt
	width: 340px
	height: 340px
	display: flex
	justify-content: center
	align-items: center
	border-radius: 100%
	text-align: center
	z-index: 2
	position: absolute
	left: calc(50% - 140px)
	top: calc(25% - 140px)
	span
		width: 100%
		text-align: center
	svg
		width: 175px
		transition: 0.3s
	&:hover
		svg
			width: 100%
			height: 100%
.tg
	width: 38%
	height: 232px
	display: flex
	align-items: center
	justify-content: center
	position: absolute
	right: 0
	bottom: 0
	z-index: 1
	+max-screen(1360px)
		span
			margin-top: 35px
	svg
		transition: 0.3s
	&:hover
		svg
			transform: rotate(45deg)
