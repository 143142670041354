@import '~/styles'

.body
  font-size: 20px
  line-height: 140%
  font-weight: 400

  +max-screen($screen-lg-down)
    font-size: 16px

  &__small
    font-size: 16px
    line-height: 110%

    +max-screen($screen-lg-down)
      font-size: 14px

.caption
  font-size: 14px
  line-height: 110%
  font-weight: 400

  +max-screen($screen-lg-down)
    font-size: 12px
    line-height: 120%

  &__small
    font-size: 12px
    line-height: 140%

    +max-screen($screen-lg-down)
      font-size: 10px
      line-height: 120%

  &.bold
    font-weight: 700
