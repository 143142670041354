@import '~src/styles'

.container
    @import './typog.sass'
    @import "./typog-client"

    max-width: $content-max-w
    margin: 0 auto
    padding: 0 $content-padding-x

    word-break: break-word
    font-size: 20px
    [class~="embed"]
        div, iframe, span
            max-width: 100% !important
        &[data-name="twitter"]
            iframe
                width: 500px !important
        &[data-name="youtube"]
            iframe
                max-height: calc((100vw - 160px) / 12 * 9)
                border-radius: 4px
    a
        color: $black
        border-bottom-width: 1px
        border-bottom-style: dotted
        border-bottom-color: currentColor
        cursor: pointer
        &:hover
            border-bottom-style: solid
            color: $purple-dark

.wrap
    position: relative
    width: 100%
    max-width: 778px
    padding: 54px 0 0
    margin-left: auto
    margin-right: auto

    +max-screen($screen-lg-down)
        padding-top: 32px

    > :first-child
        margin-top: 0

.wrap_footer
    position: relative
    width: 100%
    max-width: 778px
    margin-left: auto
    margin-right: auto
    @media screen and (max-width: 768px)
        max-width: 100%

.limit
    position: relative
    max-height: 50vh
    overflow: hidden
    &:after
        content: ''
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        height: 50%
        background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%)

.wrap_paywall
    max-width: $content-max-w
    margin: 0 auto
    padding: 0 $content-padding-x

    > *
        grid-area: B

    @media screen and (max-width: 768px)
        display: inherit

.social_group
    max-width: calc(778px + 2 * $content-padding-x)
    margin: 54px auto auto
    padding: 0 $content-padding-x

    display: flex
    align-items: center
    justify-content: space-between

    +max-screen($screen-lg-down)
        margin-top: 32px

.line
    border: 0
    border-top: 1px solid #ccc
    margin: 20px 0 30px