@import './PageReportHeaderNew'

.report
  margin-top: ($reportHeaderH - $imgH - $paddingTopHeader - 47px) * -1
  margin-top: 0
  min-height: 700px
  +max-screen($screen-md)
    // margin-top: 40px

  p
    line-height: 1.3

  &__buyButton
    width: 100%
    max-width: 290px
    position: sticky
    bottom: 40px

  &__description
    max-width: 930px
    font-size: 30px
    line-height: 35px
    padding: 2.5em 16px 0
    position: relative
    width: 100%
    max-width: 721px
    margin-left: auto
    margin-right: auto
    +max-screen($screen-md)
      font-size: 19px
      line-height: 24px

  &__content
    @import '../post/typog.sass'
    position: relative
    width: 100%
    max-width: 721px
    padding: 2.5em 16px 5em
    margin-left: auto
    margin-right: auto
    @media screen and (max-width: 768px)
      margin-bottom: 30px
      padding: 2em 16px 5em
      max-width: 100%

    +max-screen($screen-md)
      font-size: 15px
      line-height: 21px

