@import '~/styles'

.container
  width: 281px
  min-width: 281px
  height: 281px

.preview
  display: block
  width: 100%
  height: 100%
  position: relative
  background-color: #ccc
  border-radius: 4px
  padding: 20px
  cursor: default
  &_tag
    font-size: 12px
    text-transform: uppercase
    margin-bottom: 18px
  &_title
    font-size: 35px
    line-height: 33px
    color: #fff
    white-space: pre-wrap
  &_wrap
    position: relative
    z-index: 2
  &_bg
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1
    background-color: $orange
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    border-radius: 4px
    overflow: hidden
    filter: grayscale(1) invert(1) contrast(0.3) brightness(1.2)
    @extend %RICHTEXT-IMAGE

A.preview
  transition: 0.3s border-radius
  cursor: pointer
  overflow: hidden
  &:hover
    border-radius: 11px
    border-bottom-right-radius: 45px
    .corner
      bottom: -2px
      right: -1px
  .preview_bg
    filter: none !important
  .corner
    position: absolute
    width: 37px
    height: 36px
    z-index: 1
    bottom: -20px
    right: -20px
    transition: 0.3s all

.list
  width: 100%
  padding-top: 23px
  &_header
    display: flex
    justify-content: space-between
    align-items: center
  &_title
    font-size: 30px
    line-height: 33px
    margin-bottom: 7px
  &_icons
    margin-right: -15px
  &_icon
    padding: 15px
    font-size: 30px
    font-family: 'cofo'
    &:hover
      color: $orange
  &_scroll
    width: 100%
    overflow-y: hidden
    overflow-x: auto
    padding-bottom: 30px
    @include no-scrollbar
  &_wrap
    display: inline-flex
    .container
      margin-right: 13px
      &:last-child
        margin-right: 0 !important
  +max-screen($screen-md)
    &_icons
      position: absolute
      visibility: hidden
    &_scroll
      margin-left: -$padd
      margin-right: -$padd
      padding-left: $padd
      padding-right: $padd
      width: calc(100% + #{$padd * 2})
    &_wrap
      .container
        margin-right: 10px
