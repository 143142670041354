@import '~/styles'
  
.wrapper
  position: relative

  +max-screen($screen-xxs)
    width: 100%

.wrapper button
  position: relative
  flex-shrink: 0
  width: fit-content
  height: 106px
  background: #F8F8F8
  border: 1px solid #EFEFEF
  border-radius: 53px
  transition: transform 0.1s
  z-index: 1
  @include no-highlights

  &:hover
    color: $purple
    fill: $purple

  &:disabled
    cursor: unset

    &:hover
      color: #000
      fill: #000

    &:active
      transform: none

  &:active
    transform: scale(0.95)

  &.btn_active
    transform: scale(0.95)

  +max-screen($screen-md)
    width: 100%

.post_progress
  cursor: unset

  &:hover
    color: $white

  .inner
    color: $white

    svg
      width: 0
      overflow: hidden
      margin-left: -12px

.post_success
  &:hover
    color: #000
    fill: #000

  .overlay
    animation: animateOut 0.75s


.overlay
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background: linear-gradient(90.22deg, #B688E4 0.18%, #DD88E4 99.81%)
  opacity: 0
  transition: opacity 0.2s
  border-radius: 53px
  overflow: hidden


.inner
  position: relative
  display: flex
  justify-content: center
  align-items: center
  gap: 20px

  width: 100%
  height: 100%
  padding-left: 40px
  padding-right: 48px

  z-index: 1
  transition: 0.2s

  svg
    width: 60px
    height: 60px
    flex-shrink: 0
    transition: .2s width, .2s margin-left

  span
    flex-grow: 1
    text-align: center

  &_dirty
    color: $purple
    fill: $purple

.notification
  font-size: 12px
  line-height: 26px
  text-align: center
  color: #9F9F9F
  position: absolute
  left: 0
  right: 0
  top: 6px
  transition: top ease-in 0.2s

  &_active
    top: -36px

    +max-screen($screen-lg-down)
      top: -28px

@keyframes animateOut
  0%
    opacity: 1
    cursor: unset
    pointer-events: none
  100%
    opacity: 0
    cursor: pointer
    pointer-events: none