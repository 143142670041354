@import '~/styles'

.reportPreview
  display: flex
  flex-direction: column
  align-items: self-start
  width: calc(33.33%)
  margin-top: 30px
  padding: 0.8%

  +max-screen(850px)
    width: calc(50%)
    padding: 1.2%

  +max-screen($screen-sm-down)
    margin-top: 10px
    box-sizing: border-box
    margin: 20.3px 0 0 0
    width: 100%

  &:hover
    .title
      color: $orange
      transition: 0.3s color
    .img
      transition: 0.3s box-shadow
      box-shadow: 0px 11px 12px rgba(0, 0, 0, 0.2)

  &__imgs
    display: none
    overflow-y: hidden
    overflow-x: auto
    height: 235.87px
    width: 100%
    margin-left: -10px
    @include no-scrollbar
    +max-screen($screen-sm-down)
      display: flex

  &__imgItem
    display: inline
    width: 166.69px
    height: 100%
    margin-left: 10px
    border-radius: 4px


  &__price
    margin-top: 10px

  &__count
    color: $orange

  &__code
    font-size: 15px
    line-height: 20px
    color: $gray

  &__description
    margin-top: 12px
    font-size: 15px
    line-height: 20px
    margin-right: 23px
    overflow: hidden
    +max-screen($screen-md)
      height: 100px
    +max-screen($screen-sm-down)
      height: auto

  &__hr
    width: 100%
    height: 1px
    margin-bottom: 30px
    background-color: rgb(0, 0, 0)
    +max-screen($screen-sm-down)
      margin-bottom: 20px

.title
  margin-top: 12px
  font-size: 30px
  line-height: 30px
  height: 65px
  font-weight: 500
  +max-screen($screen-md-down)
    font-size: 28px

  +max-screen($screen-sm)
    font-size: 30px

  +max-screen($screen-sm-down)
    height: auto
    margin-top: 17px

.img
  cursor: pointer
  width: 100%
  max-height: 520px
  height: 100%
  border-radius: 4px
  object-fit: cover
  +max-screen($screen-sm-down)
    display: none

.link
  width: 100%

