@import '~/styles'

.sidebar
    &_list
        border-bottom-width: 1px
        border-bottom-style: solid
        border-image: linear-gradient( to right, black, rgba(0, 0, 0, 0) ) 1
        +max-screen($screen-md)
            border-image: linear-gradient( to right, white, rgba(0, 0, 0, 0) ) 1
    &_skeleton
        height: 18px
        background-color: #eee
        background: linear-gradient(100deg,hsla(0,0%,100%,0) 40%,lab(64.24 55.84 52.7 / 0.1) 50%,hsla(0,0%,100%,0) 60%) #fff
        background-size: 200% 100%
        background-position-x: 180%
        animation: loading 2s ease-in-out infinite

@keyframes loading
    to
        background-position-x: -20%
