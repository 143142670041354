@import '~/styles/'

.alertwrapper
  margin: 0 auto
  width: 100%
  max-width: 100%
  position: relative

  .gradient
    position: absolute
    width: 100%
    height: 400px
    top: -400px
    z-index: 2
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0) 100%)
    transform: rotate(-180deg)

  .container_wrapper
    border: 1px solid #EFEFEF
    margin: 15px 0 60px
    .lollypop
      background: url("~images/paywall/Rectangle69.png")
      background-repeat: repeat-x
      position: relative
      height: 16px
      width: 100%
      border: 1px solid #EFEFEF

    .content_wrapper1
      display: flex
      justify-content: space-between

      .column1
        padding: 30px
        .title_wrapper
          .title
            font-weight: 500
            font-size: 40px
            line-height: 36px
            color: #373737
      .column2
        display: flex
        flex-direction: column
        align-items: center
        margin-right: 30px
        padding-top: 30px

        a
          width: 100%

        .button1
          width: 155px
          height: 47px
          background: #FF6B40
          border: 1px solid #FF6B40
          border-radius: 4px
          color: $white
          font-weight: 400
          font-size: 16px
          line-height: 14px
        .link
          margin: 20px 0 30px
          text-decoration: none
          color: #000000
          font-weight: 400
          font-size: 12px
          line-height: 14px

    hr
      border-top: 1px solid #EFEFEF
      border-bottom: 0
      border-left: 0
      border-right: 0
      margin: 0 35px

    .content_wrapper2
      .description_wrapper
        padding: 16px 30px 0 30px

        .description
          display: flex
          align-items: baseline
          font-weight: 400
          font-size: 12px
          line-height: 14px
          b
            font-weight: 500
            font-size: 20px
            line-height: 22px
            margin: 0

        >div:nth-child(2)
          margin-top: 6px
          margin-bottom: 30px

        a
          text-decoration: none
          color: #FF6B40

@media (max-width: 768px)
  .alertwrapper
    position: relative

    .gradient
      height: 100vw
      top: -100vw
      z-index: 2

    .container_wrapper
      overflow: hidden
      display: flex
      flex-direction: column
      width: 100%
      margin-bottom: 37px

      .lollypop
        height: 18px

      .content_wrapper1
        align-items: center
        flex-direction: column
        width: 100%

        .column1
          padding: 20px 20px 0
          .title_wrapper
            .title
              font-weight: 500
              font-size: 26px
              line-height: 23.4px
              color: #373737

        .column2
          padding: 30px 20px 0
          width: 100%
          box-sizing: border-box
          margin-right: 0

          .button1
            width: 100%
          a
            margin-bottom: 27.5px

      hr
        margin: 0 20px

      .content_wrapper2
        display: flex
        align-items: center
        flex-direction: column
        width: 100%

        .description_wrapper
          margin-bottom: 0
          .description
            b
              font-weight: 500
              font-size: 18px
              line-height: 20px
              margin: 0 3px

          >div:nth-child(2)
            margin-top: 6px
            margin-bottom: 20px

.paywall_wrapper
  display: flex
  max-width: 721px
  flex-direction: column
  gap: 20px
  margin: 24px auto 0 auto
  text-align: center

  &__btn_has_subscription
    width: fit-content
    align-self: center
    background: none
    border: 1px solid $purple-dark
    color: $purple-dark
    height: 48px
    margin-bottom: 12px

    &:hover
      background: $purple-dark
      color: $white

    +max-screen($screen-lg-down)
      height: 40px

    > p
      transition: .2s color
      font-size: 20px

.paywall_subscription_item
  border: 1px solid $black-25

.header
  &_wrapper
    border-top: 1px solid #F85B2E
    padding-top: 24px
    margin-bottom: 32px
    display: flex
    flex-direction: row
    align-items: flex-start
    gap: 16px
    align-self: stretch
    font-size: 20px
    font-weight: 400
    line-height: 22px
    @media (max-width: 768px)
      flex-direction: column
    span
      font-weight: 700

  &_already_sub
    border-radius: 4px
    border: 1px solid #000
    background:  #FFF
    display: flex
    height: 52px
    padding: 12px 16px
    justify-content: center
    align-items: center
    min-width: 230px
    @media (max-width: 768px)
      width: 100%

.plans_wrapper
  display: flex
  flex-direction: row
  align-items: flex-start
  gap: 40px
  align-self: stretch
  max-width: 721px
  margin-bottom: 48px
  @media (max-width: 768px)
    flex-direction: column
// justify-content: space-between

.plan
  &_discount
    display: flex
    padding: 8px
    justify-content: center
    align-items: center
    gap: 10px
    position: relative
    right: -40px
    top: -40px
    border-radius: 8px
    height: 40px
    background: linear-gradient(270deg, #F85B2E 0.06%, #B688E4 99.92%)
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12)
    color: $white
    text-align: center
    font-size: 24px
    font-style: normal
    font-weight: 500
    line-height: 24px
    &_wrapper
      display: flex
      // position: absolute
      justify-content: right
      height: 0
      width: 100%
  &_item
    display: flex
    padding: 32px
    flex-direction: column
    align-items: flex-start
    // gap: 16px
    flex: 1 0 0
    align-self: stretch
    border-radius: 8px
    background: #FFF
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12)
    max-width: 340px

  &_title
    color: #000
    font-size: 24px
    font-style: normal
    font-weight: 500
    line-height: 24px
    &_fullprice
      color: #A5A5A5
      text-align: center
      font-size: 20px
      font-style: normal
      font-weight: 400
      line-height: 24px

  &_button
    display: flex
    height: 52px
    padding: 12px 16px
    justify-content: center
    align-items: center
    gap: 12px
    align-self: stretch
    border-radius: 4px
    background: $orange
    color: $white
    margin: 8px 0
  &_description
    color: #A5A5A5
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 16px
  &_benefits
    margin-top: 16px
    display: flex
    flex-direction: column
    gap: 12px
    color: #000
    font-size: 18px
    font-style: normal
    font-weight: 400
    line-height: normal
    li
      display: flex
      gap: 12px
      flex-direction: row
    svg
      min-width: 18px
    b
      font-size: 18px
      font-style: normal
      font-weight: 500
      line-height: normal
    span
      color: $orange
      font-size: 18px
      font-style: normal
      font-weight: 500
      line-height: 20px