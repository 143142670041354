.profile_username
  margin-top: 2px
  overflow: hidden
  text-overflow: ellipsis

.reminder_badge_content
  display: flex
  align-items: center
  gap: 2px
  font-weight: 700

  > svg
    width: 12px
    height: 12px
    transform: translateY(-1px)
