@import '~/styles'

.container
    max-width: $content-max-w
    margin: 0 auto
    padding: 60px $content-padding-x 0 $content-padding-x
    position: relative
    z-index: 999
    @extend %RICHTEXT-IMAGE

    +max-screen($screen-lg-down)
        padding-top: 32px

.title
    margin-top: 24px

    +max-screen($screen-lg-down)
        margin-top: 16px

.description
    margin-top: 24px

    +max-screen($screen-lg-down)
        margin-top: 16px

.cover
    width: 100%
    max-height: 100vh
    overflow: hidden
    object-fit: cover
    border-radius: 5px
    +max-screen($screen-sm)
        width: calc(100% + 40px)
        margin-left: -20px
        margin-right: -20px
        border-radius: 0px
        margin-bottom: 0px

.footer
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin-top: 24px
    margin-bottom: 32px

    +max-screen($screen-lg-down)
        margin-top: 16px
        margin-bottom: 16px

.authors
    display: flex
    font-size: 12px
    line-height: 14px

    width: 100%
    margin-left: auto
    margin-right: auto

    &__title
        margin-right: 4px

.image_title
    color: $black-40
    margin-top: 8px

.labels
    display: flex
    flex-wrap: wrap
    gap: 12px

    +max-screen($screen-lg-down)
        gap: 8px

.imagePartnerLogo
  display: block !important
  padding-left: 8px

.authors_reminder_plus
    margin-right: 4px
