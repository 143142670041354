@import '~/styles'

.container
    position: relative
    font-family: 'cofo'
    margin: 20px 0
    padding: 25px 14px 4px
    background-color: #f2f2f2
    border-radius: 4px
    +max-screen($screen-sm-down)
        padding: 10px 12px

.title
    font-size: 30px
    line-height: 33px
    margin-top: 0
    margin-bottom: 12px
    +max-screen($screen-sm-down)
        font-size: 20px
        line-height: 25px
        margin-bottom: 0

.button
    position: absolute
    top: 19px
    right: 14px
    font-size: 20px
    line-height: 35px
    padding: 4px 32px 6px
    background-color: white
    transition: 0.3s
    cursor: pointer
    border-radius: 4px
    &:hover
        background-color: $orange
        color: $white
    +max-screen($screen-sm-down)
        position: static
        display: block
        text-align: center

.list
    display: grid
    column-gap: 25px
    grid-template-columns: repeat(3, 1fr)
    +max-screen($screen-lg-down)
        column-gap: 17px
    +max-screen($screen-sm-down)
        grid-template-columns: 1fr
    & > div
        padding-top: 20px
        &:nth-child(n + 4)
            border-top: 1px dashed black
        &:nth-child(n + 2)
            +max-screen($screen-sm-down)
                border-top: 1px dashed black
        &:nth-child(n + 4)
            +max-screen($screen-sm-down)
                display: none
