@import '~/styles/'

.header_card
  background: $white
  height: 320px
  padding: 32px 51px 0 51px
  border-radius: 8px
  width: 33.33%

  +max-screen($screen-lg-down)
    padding: 20px

  +max-screen($main-md)
    width: 100%
    height: auto

  &__image
    margin-bottom: 13px
    width: 100%

    +max-screen($main-md)
      width: auto
      max-height: 120px
