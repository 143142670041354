@import '~/styles/'
@import '../page/PageMain.sass'

.social
	@extend .content
	max-width: 362px
	margin: 0 0 0 30px
	padding-left: 0
	padding-right: 0
	+max-screen($main-md-2)
		padding-top: 0
	+max-screen($screen-md)
		padding-bottom: 0
	+max-screen($screen-sm-down)
		margin: 30px 0 0 0
	&_tit
		display: flex
	&_list
		display: flex
		flex-wrap: wrap
		background: #FFFFFF
		border-radius: 4px
		border-style: solid
		border-color: #EFEFEF
		border-width: 1px 0 0 1px
		+max-screen($screen-sm-down)
			flex-wrap: nowrap
		&_item
			display: flex
			justify-content: center
			align-items: center
			width: 50%
			height: 55px
			cursor: pointer
			background-repeat: no-repeat
			background-position: center
			transition: 0.3s
			color: #000
			border-style: solid
			border-color: #EFEFEF
			border-width: 0 1px 1px 0
			svg
				fill: currentColor
			&:hover
				color: #fffd
		&_vk
			&:hover
				background-color: #2172D0
		&_fcb
			&:hover
				background-color: #16549C
		&_twt
			&:hover
				background-color: #25D3FF
		&_tlg
			&:hover
				background-color: #29B0E3

	+max-screen($main-md-2)
		flex-direction: column
		align-items: flex-start
		&_tit
			margin-bottom: 14px

