@import '~/styles/'

.review_card
  width: 360px
  height: 420px
  background-color: $neutral-70
  padding: 24px 28px
  border-radius: 8px

  display: flex
  flex-direction: column
  justify-content: space-between
  flex-shrink: 0

  position: relative

  +max-screen($screen-lg-down)
    width: 320px
    height: 380px

  &::before
    content: '“'
    position: absolute
    top: 18px
    left: 12px

    font-size: 30px
    font-weight: 700

    +max-screen($screen-lg-down)
      font-size: 26px
      line-height: 100%
      top: 24px
      left: 14px

  .review_text
    display: -webkit-box
    -webkit-line-clamp: 8
    -webkit-box-orient: vertical
    overflow: hidden

    &::after
      content: '”'

.author_section
  .avatar
    display: block
    width: 52px
    height: 52px
    border-radius: 50%
    margin-bottom: 8px
    object-fit: cover

  .avatar_placeholder
    background: $black-40

  .position
    white-space: pre
