@import '~/styles'

.headerLongread
    position: relative
    max-width: $maxW
    margin-right: auto
    background: white
    width: 100%
    height: 701px
    margin-bottom: -235px
    z-index: 1000
    +max-screen(1280px)
        margin-bottom: -19vw
    +max-screen($screen-md)
        display: none
