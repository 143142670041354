@import '~/styles'

.search
  width: 100%
  max-width: 800px
  &.exist
    .icon
      opacity: 1
  &.loading
    .icon svg
      animation: spin 2s linear infinite
  +max-screen(680px)
    padding: 15px 0 5px

.line
  display: flex
  align-items: center
  width: 100%
  padding: 20px 0
  +max-screen(680px)
    padding: 0
    margin-bottom: 15px

.input
  width: 100%
  font-size: 55px
  line-height: 50px
  &::placeholder
    color: currentColor
    opacity: 0.45
  +max-screen(680px)
    font-size: 30px
    line-height: 50px

.icon
  opacity: 0.45
  cursor: pointer
  margin-left: auto
  svg
    width: 37px
    height: 37px
    fill: currentColor

@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(366deg)

.title
  width: 83px
  font-size: 15px
  line-height: 20px
  white-space: nowrap

.value
  font-size: 15px
  line-height: 20px
  span
    color: $orange

