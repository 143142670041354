@import '~/styles/'

.container
  padding: 60px 16px 0
  max-width: 1002px
  margin: 0 auto

.author_item
  display: flex
  align-items: center
  gap: 16px

  &__avatar
    display: block
    width: 52px
    height: 52px
    border-radius: 50%
    overflow: hidden
    flex-shrink: 0

  &__name
    margin-bottom: 1px
    font-size: 24px
    font-weight: 700

.bio
  margin-top: 24px
  color: gray-dark
  a
    text-decoration: underline
