@import '~/styles/'

.modal
  z-index: 100
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  &__content
    outline: none
    position: fixed
    top: 50%
    left: 50%
    right: auto
    bottom: auto
    margin-right: -50%
    transform: translate(-50%, -50%)

.subscription
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  border-radius: 20px
  width: 630px
  padding: 15.5px
  background-color: rgb(196, 157, 249)
  +max-screen($screen-sm)
    width: 280px
    padding-bottom: 20px
  &__button
    position: absolute
    top: 10px
    right: 10px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    width: 20px
    height: 20px
    background-color: rgb(177, 136, 234)
    &:hover
      background-color: rgb(248, 91, 46)
  &__content
    display: flex
    align-items: center
    flex-wrap: wrap
    margin-top: 27px
    +max-screen($screen-sm)
      flex-direction: column
  &__text
    color: rgb(255, 255, 255)
  &__title
    font-size: 40px
    font-weight: 500
    +max-screen($screen-sm)
      font-size: 25px
  &__description
    margin-top: 10px
    width: 295px
    text-align: left
    font-size: 18px
    line-height: 22px
    +max-screen($screen-sm)
      width: 228px
      font-size: 15px
  &__img
    +max-screen($screen-sm)
      width: 183px
      height: 155px
  &__form
    display: flex
    align-items: center
    justify-content: center
    margin-top: 16px
    box-shadow: 0px 10px 15px rgba(0, 0, 0, .25)
    border-radius: 4px
    width: 120%
    height: 60px
    background-color: rgb(255, 255, 255)
    +max-screen($screen-sm)
      margin-top: 0
    FORM
      display: flex
      height: 100%
      width: 100%
    INPUT
      box-sizing: border-box
      width: 69%
      height: 100%
      padding: 0 25px
      &::placeholder
        color: rgb(0, 0, 0)
      +max-screen($screen-sm)
        width: 120%
    BUTTON
      box-sizing: border-box
      display: flex
      justify-content: center
      align-items: center
      border-left: 1px solid rgb(196, 157, 249)
      width: 31%
      height: 100%
      svg
        display: none
      +max-screen($screen-sm)
        svg
          display: block
        span
          display: none
  &__footer
    margin-top: 9.5px
    font-size: 16px
    color: rgba(0, 0, 0, 0.35)
    +max-screen($screen-sm)
      font-size: 12px
      padding-right: 10%

.form
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  width: 100%
  height: 100%
  &__title
    &_error
      font-size: 20px
      font-weight: 400
      line-height: 18px
      +max-screen($screen-sm)
        font-size: 15px
    &_subscription
      width: 50%
      text-align: center
      font-size: 22px
      +max-screen($screen-sm)
        width: 70%
        font-size: 15px
  &__description
    display: flex
    justify-content: center
    text-align: center
    flex-wrap: wrap
    margin-top: 6px
    font-size: 12px
    color: rgba(0, 0, 0, .35)
