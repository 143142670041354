@import '~/styles'

.gift
    max-width: $content-max-w
    margin: 0 auto
    padding: 0 $content-padding-x
    color: #000
    font-size: 55px
    font-style: normal  
    font-weight: 500
    line-height: 100%

    @media (max-width: 767px)
        font-size: 35px
        line-height: 100%
    &_header
        padding-bottom: 36px
        display: flex
        flex-direction: row
        justify-content: space-between
        @media (max-width: 767px)
        padding-bottom: 16px
        &_icon
            margin-top: 22px
            @media (max-width: 767px)
                display: none
    &_title
        padding-top: 15px
        margin-bottom: 24px
        font-family: 'cofo'
        @media (max-width: 767px)
            margin-bottom: 12px
    &_subtitle
        color: #000
        font-feature-settings: 'clig' off, 'liga' off
        font-size: 24px
        font-style: normal
        font-weight: 500
        line-height: 32px
    &_text
        font-feature-settings: 'clig' off, 'liga' off
        font-size: 30px
        font-style: normal
        font-weight: 400
        line-height: 100%
        @media (max-width: 767px)
            font-size: 20px 
            font-style: normal
            font-weight: 400
            line-height: 100%
    &_submit
        display: flex
        width: 240px
        padding: 12px 16px
        justify-content: center
        align-items: center
        gap: 12px
        border-radius: 4px
        background: $orange
        color: $white
        text-align: center
        font-feature-settings: 'clig' off, 'liga' off
        font-size: 20px
        font-style: normal
        font-weight: 400
        line-height: 24px
        margin: 32px 0px
        min-width: 360px
        width: 360px
        @media (max-width: 767px)
            margin: 24px 0px
            min-width: auto
            max-width: 360px
            width: 100%
        &_enabled    
            color: $white
            background: $orange
            &:hover
                background: #FF764E
        &_disabled
            background: #E3E2E2
            color: #A5A5A5
            cursor: not-allowed

    &_content
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        gap: 40px
        margin: 32px 0
        @media (max-width: 806px)
            flex-direction: column
        &_icon
            position: absolute
            margin-top: 44px
            margin-left: 33px
            overflow: hidden
            border-radius: 30px
            width: 280px
            height: 396px
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08)
            background-image: url("~/images/gift/giftpdf.png")
            background-size: contain
            background-repeat: no-repeat
            background-position: bottom right   
        
    &_amount
        font-feature-settings: 'clig' off, 'liga' off
        font-size: 52px
        font-style: normal
        font-weight: 900
        line-height: 50px
        font-family: Arial
    &_steps
        display: flex
        flex-direction: row
        justify-content: space-between
        gap: 40px
        @media (max-width: 767px)
            flex-direction: column
            gap: 24px
        &_item
            font-feature-settings: 'clig' off, 'liga' off
            font-size: 18px
            font-style: normal
            font-weight: 400
            line-height: normal
            display: flex
            flex-direction: row
            gap: 16px
            max-width: 360px
            min-width: 220px
        div p
            margin-top: 8px
            margin-bottom: 4px

            font-feature-settings: 'clig' off, 'liga' off
            font-size: 20px
            font-style: normal
            font-weight: 500
            line-height: 24px
            
.line
    margin: 32px 0
    padding: 0
    height: 0
    border: none
    border-top: 1px dashed #000
