@import '~/styles'

+min-screen($main-xs)
  .wrapper
    margin-left: auto
    position: absolute
    right: 0
    top: -17px
  .logo
    width: 120px
  .text
    display: inline-block
    font-size: 13px
    font-weight: bold
    position: relative
    bottom: 9px
    left: -12px
+max-screen($main-xs)
  .wrapper
    width: 100%
    display: flex
    margin-top: 10px
  .text
    align-self: center
    margin-top: 21px
    margin-right: 14px
    font-size: 13px
    font-weight: bold
  .logo
    width: 120px