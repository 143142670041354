@import '~/styles'

.guide_contents
  padding: 30px

  display: flex
  flex-direction: column
  gap: 24px

  @extend %fancy-scrollbar
