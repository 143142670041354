@import '~/styles/'

.search_screen
  max-width: $content-max-w
  margin: 0 auto
  padding: 16px $content-padding-x 0 $content-padding-x

.search_screen_before_scroll_reset_stub
  background: $bg-dark
  height: 100vh
