@import '~/styles'

.form
  display: flex 
  flex-direction: column
  align-items: center
  gap: 12px
  width: 100%
  max-width: 560px
  font-size: 20px
  font-weight: 400
  line-height: 24px
  &_item
    align-self: stretch
  &_title
    align-self: stretch
    color: #000
    text-align: left
    font-feature-settings: 'clig' off, 'liga' off
    font-style: normal
    font-weight: 500
    font-size: 32px
    line-height: 36px    
    @media (max-width: 1170px)
       font-size: 22px
    @media (max-width: 767px)
      font-feature-settings: 'clig' off, 'liga' off
      font-size: 22px
      font-style: normal
      font-weight: 500
      line-height: 100%
    &_crossed
      color: #A5A5A5
      font-weight: 400
      &_line
        position: absolute
        width: 93px
        height: 2px
        transform: rotate(-10deg)
        background-color: #A5A5A5
        margin-top: 16px
        align-self: stretch        
        @media (max-width: 1170px)
          width: 63px
          margin-top: 18px

.prices
  display: flex
  gap: 10px

.label 
   align-self: stretch
   margin-top: -16px

.inputRadio,
.inputText
  padding: 12px 16px
  border-radius: 4px
  border: 1px solid #A5A5A5
  width: 100%
  height: 48px
  font-feature-settings: 'clig' off, 'liga' off
  font-size: 20px
  font-style: normal
  font-weight: 400
  line-height: 24px

.info
  width: 100%
  margin-top: 4px
  color: #7F7F7F
  font-feature-settings: 'clig' off, 'liga' off
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 16px

.oferta
  color: #A5A5A5
  text-align: center
  font-feature-settings: 'clig' off, 'liga' off
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 24px
  @media (max-width: 767px)
    line-height: 20px
  &Link
    text-decoration: underline

.btn
  display: flex
  padding: 12px 16px
  align-items: center
  justify-content: center
  gap: 12px
  border-radius: 4px
  align-self: stretch
  width: 100%
  height: 48px
  &_enabled
    color: $white
    background: $orange
    &:hover
      background: #FF764E
  &_disabled
    background: #E3E2E2
    color: #A5A5A5
    cursor: not-allowed

.radio
  display: flex
  flex-direction: row
  justify-content: space-between
  &_title
    color: #000
    font-feature-settings: 'clig' off, 'liga' off
    font-size: 20px
    font-style: normal
    font-weight: 400
    line-height: 24px
  &_text
    color: #7F7F7F
    font-feature-settings: 'clig' off, 'liga' off
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 16px
  &_btn
    &_active
      margin-top: 12px
      height: 16px
      width: 16px
      min-width: 16px
      border-radius: 8px
      border: 4px solid #F85B2E
      cursor: pointer
    &_inactive
      margin-top: 12px
      height: 16px
      width: 16px
      min-width: 16px
      border-radius: 8px
      border: 1px solid #A5A5A5
      cursor: pointer

.checkbox
  -webkit-appearance: auto
  margin-right: 16px
  height: 16px
  width: 16px
  accent-color: $orange

.line
  background: #E3E2E2
  height: 1px
  width: 100%
  margin: 4px 0

.pdf
  width: 595px
  height: 842px
  display: flex
  flex-direction: column
  align-items: center

  &_qr
    position: absolute
    margin-left: 170px
    margin-top: -26px
    padding: 17px 17px 8px 17px
    border-radius: 8px
    background: $white
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08)
  &_promo_wrapper
    display: flex
    padding: 16px 52px 16px 36px
    flex-direction: column
    align-items: flex-start
    gap: 4px
    border-radius: 8px
    border: 2px solid #F85B2E
    background: $white
    width: 245px
    margin: 32px 0
    p
      color: #000
      font-feature-settings: 'clig' off, 'liga' off
      font-size: 18px
      font-style: normal
      font-weight: 400
      line-height: 20px
    span
      color: #000
      font-feature-settings: 'clig' off, 'liga' off
      font-size: 48px
      font-style: normal
      font-weight: 500
      line-height: 48px
      letter-spacing: 1px
  &_bg
    color: blue
  &_content
    color: blue
    width: 595px
    height: 842px    
    background-image: url("~/images/gift/bg-gift.svg")
    background-size: contain
    background-repeat: no-repeat
    background-position: bottom right 
    padding: 30px 30px
    &_title
      color: #000
      font-feature-settings: 'clig' off, 'liga' off
      font-size: 32px
      font-style: normal
      font-weight: 500
      line-height: 32px
      margin-top: 33px  
    &_text
      color: #000
      font-feature-settings: 'clig' off, 'liga' off
      font-size: 22px
      font-style: normal
      font-weight: 400
      line-height: 23.975px
      u
        color: #974DE0
        font-feature-settings: 'clig' off, 'liga' off
        font-size: 22px
        font-style: normal
        font-weight: 400
        line-height: 23.975px        
    &_subtitle
      color: #000
      font-feature-settings: 'clig' off, 'liga' off
      font-size: 22px
      font-style: normal
      font-weight: 700
      line-height: 32px
      margin-top: 24px
    ul
      list-style-type: disc
      margin-left: 30px
    li
      align-self: stretch
      color: #000
      font-feature-settings: 'clig' off, 'liga' off
      font-size: 22px
      font-style: normal
      font-weight: 400
      line-height: 32px

