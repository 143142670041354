// Mixins:
//   @ min-screen(width)                      // shortcut for @media screen and (min-width ...)
//   @ max-screen(width)                      // shortcut for @media screen and (max-width ...)
//   @ screen(min-width, max-width)           // shortcut for @media screen and (min-width ...) and (max-width ...)
//   ---
//   @ min-screen-height(height)              // shortcut for @media screen and (min-height ...)
//   @ max-screen-height(height)              // shortcut for @media screen and (max-height ...)
//   @ screen-height(min-height, max-height)  // shortcut for @media screen and (min-height ...) and (max-height ...)
//   ---

@mixin screen($resMin, $resMax)
{
	@media screen and (min-width: $resMin) and (max-width: $resMax) {
		@content;
	}
}

@mixin max-screen($res)
{
	@media screen and (max-width: $res - 1px)
	{
		@content;
	}
}

@mixin min-screen($res)
{
	@media screen and (min-width: #{$res})
	{
		@content;
	}
}

@mixin screen-height($resMin, $resMax)
{
	@media screen and (min-height: $resMin) and (max-height: $resMax)
	{
		@content;
	}
}

@mixin max-screen-height($res)
{
	@media screen and (max-height: $res)
	{
		@content;
	}
}

@mixin min-screen-height($res)
{
	@media screen and (min-height: #{$res + 1px})
	{
		@content;
	}
}
