@import '~/styles'

.pageHeaderSuper
  position: relative
  max-width: $maxW
  margin-right: auto
  background: #030303
  width: 100%
  height: 701px
  margin-bottom: -235px
  z-index: 1000
  overflow: hidden
  color: $white
  +max-screen(1280px)
    margin-bottom: -19vw
  +max-screen($screen-md)
    display: none

.logo
  display: flex
  width: 100%
  padding: 20px 20px 24px 20px
  svg *
    fill: transparent
    stroke: currentColor
  &:before,
  &:after
    content: ''
    display: block
    height: 1px
    position: absolute
    bottom: 0
    background-color: currentColor
  &:after
    right: 17px
    width: calc(100% - 225px - 21px - 17px - 17px)
  &:before
    left: 17px
    width: 225px
  &Start,
  &End
    position: absolute
    top: 0
    left: 0
    width: 100%
    z-index: 3
  &Start
    .logo
      position: fixed
      top: 0
      pointer-events: none
      transition: 0.3s opacity
    &:after
      content: none
    &.withBanner
      top: $banner-height
    [name="end"]
      display: none
  &End
    .logo
      &:before
        content: none
    [name="start"]
      display: none
  +max-screen(1270px)
    &:before
      width: 17.5%
    &:after
      width: calc(100% - 17.5% - 21px - 17px - 17px)

.content
  position: relative
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: flex-end
  padding-left: $menu-w
  padding-bottom: 63px
  z-index: 2
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 21.6%, rgba(0, 0, 0, 0.85) 100%)
  +max-screen(1290px)
    padding-left: $menu-w

.title
  margin-top: 24px
  font-size: 55px
  line-height: 50px

.image
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1
  figure, picture, img
    width: 100%
    height: 100%
    margin: 0
    padding: 0
    border: none
    outline: none
  img
    object-fit: cover
    object-position: center

.labels
  display: flex