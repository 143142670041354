@import '~/styles'

.topic
  display: flex
  width: min-content
  height: 20px
  &:hover
    A.item
      &:first-child
        background-color: $orange
        border: 1px solid $orange

    .item_subtitle
      color: $orange
      border-color: $orange

  &_size_lg
    +min-screen($screen-lg-down)
      height: 32px

      .item
        &:first-child
          font-size: 14px
          padding: 8px 10px

        &_subtitle
          font-size: 14px
          padding: 8px 10px

  &_dot
    .item:first-child:before
      content: ''
      display: inline-block
      width: 5px
      height: 5px
      background-color: $orange
      border-radius: 10em
      margin-right: 5px
      margin-bottom: 2px

  &_orange
    A.item
      background-color: $orange
      border: 1px solid $orange
    .item_subtitle
      border: 1px solid $orange
      color: $orange

    &:hover
      A.item
        background-color: $orange-hover !important
        border: 1px solid $orange-hover !important
      .item_subtitle
        border: 1px solid $orange-hover
        color: $orange-hover

  &_purple
    A.item
      background-color: $purple-dark
      border: 1px solid $purple-dark
    .item_subtitle
      border: 1px solid $purple-dark
      color: $purple-dark

    &:hover
      A.item
        background-color: $purple-dark-hover !important
        border: 1px solid $purple-dark-hover !important
      .item_subtitle
        border: 1px solid $purple-dark-hover
        color: $purple-dark-hover

.item
  display: inline-block
  align-items: center
  justify-content: center
  padding: 3px 10px
  border: 1px solid #000
  background-color: $white
  color: #000
  overflow: hidden
  white-space: pre
  transition: 0.3s

  &:first-child
    align-items: center
    padding: 4px 8px
    font-size: 10px
    line-height: 10px
    text-transform: uppercase
    letter-spacing: 0.6px
    border-radius: 20px 0 0 20px
    display: flex
    gap: 2px

    & + .item
      border-left: 0

  &_subtitle
    font-size: 10px
    line-height: 10px
    padding: 4px 8px
    border-radius: 0 20px 20px 0
    text-transform: uppercase

    display: flex
    align-items: center

  &_isBlack
    background-color: #000
    color: $white

.star_container
  width: 12px
  height: 12px
