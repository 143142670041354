@import '~/styles'

.page_header
  height: 64px
  border-bottom: 1px solid $border-light
  padding: 0 32px
  background-color: $white

  display: flex
  justify-content: space-between
  align-items: center

  z-index: 1000

  &.with_banner
    +max-screen($screen-md)
      position: absolute !important

  &__top_fix
    +max-screen($screen-md)
      position: fixed !important
      top: 0 !important

  &__dark
    background: $bg-dark

  +max-screen($screen-md)
    padding: 0 12px
    position: absolute
    width: 100%
    will-change: top

  &__desktop
    +max-screen($screen-md)
      display: none !important

  &__mobile
    display: none
    +max-screen($screen-md)
      display: block !important
      background-color: white
    
    &_dark
      background-color: $bg-dark
      +max-screen($screen-md)
        background-color: $bg-dark

  .logo
    width: 160px

  .hamburger_container
    width: 24px
    height: 24px
    cursor: pointer
    touch-action: unset

  .links_container
    display: flex
    align-items: center
    gap: 32px

    +max-screen($screen-lg-down)
      gap: 24px

  .link
    display: flex
    align-items: center
    gap: 8px

    &:hover
      color: $purple-dark

    &__subscription
      color: $purple-dark

      position: relative

      > svg
        width: 24px

      &:hover
        &::before
          filter: blur(0px)

      &::before
        content: ''
        position: absolute

        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

        width: 112.5%
        height: 160%
        background-color: $purple-dark
        opacity: 0.2
        border-radius: 35px

        filter: blur(5px)
        transition: .5s filter