@import '~/styles/'

.footer_container
  position: relative
  height: 639px

  display: flex
  flex-direction: column
  justify-content: space-between

  overflow: hidden

  +max-screen($screen-md)
    height: 600px
    background: $orange

  .rect_orange
    position: absolute
    bottom: 0
    left: 0

    +max-screen($screen-md)
      display: none

  .poligon_purple
    position: absolute
    bottom: -67px
    left: 485px

    +max-screen($screen-md)
      bottom: 31px
      left: 50%
      transform: translateX(-13%)

    +max-screen($screen-sm)
      transform: translateX(-25%) scale(0.63)
      bottom: 42px

  .eclipse_blue
    position: absolute
    bottom: 112px
    left: 130px

    +max-screen($screen-md)
      left: 50%
      transform: translateX(-93%)

    +max-screen($screen-sm)
      transform: translateX(-78%) scale(0.62)

  .star_blue
    position: absolute
    left: 377px
    bottom: 163px
    width: 215px
    height: 215px

    +max-screen($screen-md)
      left: 50%
      transform: translateX(-50%)

    +max-screen($screen-sm)
      width: 135px
      height: 135px
      bottom: 224px

  .header_container
    color: $white
    margin: 79px 0 0 80px
    position: relative
    z-index: 1

    display: flex
    flex-direction: column
    gap: 12px

    +max-screen($screen-sm)
      text-align: center
      margin: 24px 16px 0 16px

  .footer_title
    font-size: 54px
    font-weight: 700
    line-height: 100%

    +max-screen($screen-lg-down)
      font-size: 36px
    
  .footer_subtitle
    font-size: 24px
    line-height: 110%
    font-weight: 500

    +max-screen($screen-lg-down)
      font-size: 20px
      line-height: 100%