@import '~/styles'

.container
  display: flex
  align-items: center
  width: 100%
  padding: $padd
  background: #F8F8F8
  border-radius: 4px
  margin: $padd 0
  & + .container
    border-top: none
  &:last-child
    border-bottom: none
  &:hover
    .title
      color: $orange
    .imageWrap
      figure
        box-shadow: 0px 11px 12px rgba(0, 0, 0, 0.2)
  +max-screen($main-xs)
    flex-direction: column
    width: calc(100% + 40px)
    margin-left: -20px
    margin-right: -20px
    border-radius: 0

.content
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 100%
  padding-right: $padd
  text-align: center
  +max-screen($screen-lg-down)
    padding-right: $padd
  +max-screen($main-xs)
    padding-right: 0

  &__labels
    display: flex
    flex-wrap: wrap
    margin: -5px
    > *
      margin: 5px

.title
  display: block
  font-size: 40px
  line-height: 1
  margin-top: 20px
  word-break: break-word
  &:hover
    color: $orange
  +max-screen($screen-lg-down)
    font-size: 23px
    line-height: 25px
    margin-top: 18px

.description
  display: block
  margin-top: 15px
  font-size: 20px
  line-height: 25px
  color: #717171
  +max-screen($screen-lg-down)
    margin-top: 10px
    font-size: 14px
    line-height: 18px

.imageWrap
  @extend %RICHTEXT-IMAGE
  cursor: pointer
  figure, picture, img
    display: flex
    width: 100%
    height: 100%
    border: none
    outline: none
    border-radius: 4px
  figure
    transition: 0.3s
  img
    object-fit: cover
    object-position: center
  +max-screen($main-xs)
    margin-top: $padd

.imageWrap
  width: 380px
  min-width: 380px
  height: 380px
  +max-screen($screen-lg-down)
    width: 300px
    min-width: 300px
    height: 300px
  +max-screen($screen-md-down)
    width: 250px
    min-width: 250px
    height: 250px
  +max-screen($screen-sm-down)
    width: 100%
    min-width: 100%
    height: 100%

