@import '~/styles'

.container
    font-family: 'cofo'
    padding: 20px 0
    +max-screen($screen-sm)
        overflow: auto
        margin-left: -20px
        margin-right: -20px

.title
    font-size: 30px
    line-height: 33px
    margin-bottom: 20px
    +max-screen($screen-sm)
        display: none

.list
    display: flex
    gap: 10px
    flex-wrap: wrap
    +max-screen($screen-sm)
        gap: 6.7px
        min-width: 471px
        margin-left: 20px
