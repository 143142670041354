@import '~/styles/'

.sticky_button
  position: fixed
  bottom: 24px
  left: 50%
  transform: translateX(-50%)

  padding-right: 20px
  background-color: $white
  border: 1px solid #0000000A
  box-shadow: 0 16px 32px -6px $black-25
  box-sizing: border-box

  z-index: 100
  cursor: pointer
  transition: .2s filter
  @include no-highlights

  &:hover
    opacity: 1
    background-color: $white

  &_blured
    filter: blur(4px)

  &__text_label
    font-size: 14px
