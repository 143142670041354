@import '~/styles'

.title
  font-weight: 700
  line-height: 100%

  &__level_1
    font-size: 54px

    +max-screen($screen-lg-down)
      font-size: 36px

  &__level_2
    font-size: 30px
    line-height: 110%

    +max-screen($screen-lg-down)
      font-size: 26px
      line-height: 100%

  &__level_3
    font-size: 22px
    line-height: 110%

    +max-screen($screen-lg-down)
      font-size: 22px
      line-height: 100%

  &__level_4
    font-size: 24px
    line-height: 110%
    font-weight: 500

    +max-screen($screen-lg-down)
      font-size: 20px
      line-height: 100%