@import '~/styles/'

.carousel
  max-width: 970px

.carousel_header
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 32px

  &_empty_title
    +max-screen($screen-sm)
      display: none

  +max-screen($screen-lg-down)
    margin-bottom: 24px

  &_buttons_container
    display: flex
    gap: 12px

    +max-screen($screen-sm)
      display: none

  &_button
    width: 40px
    height: 40px
    border-radius: 50%
    transition: .2s opacity
    position: relative

    display: flex
    justify-content: center
    align-items: center

    &:hover
      &::before
        filter: blur(0)

    &::before
      content: ''
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      background: $white-8
      border-radius: 50%
      filter: blur(2px)
      transition: .5s filter

    &__disabled
      opacity: 0.4
      cursor: default
      &:hover
        opacity: 0.4

.items_container
  display: flex
  gap: 16px
  overflow-x: scroll
  border-radius: 8px

  @include no-scrollbar