@import '~/styles'

.post_carousel_card
  height: 420px
  width: 360px
  background: $neutral-70
  border-radius: 8px
  padding: 24px 28px
  flex-shrink: 0
  transition: .2s color

  position: relative

  display: flex
  flex-direction: column
  gap: 16px

  +max-screen($screen-lg-down)
    width: 320px
    height: 360px

  &_dark
    background: $black
    color: $white

    &::after
      content: ''
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3%, rgba(0, 0, 0, 0.2) 51.81%, $black 100%)
      border-radius: inherit

    .post_carousel_card__title
      +max-screen($screen-lg-down)
        font-size: 20px
        margin-bottom: 8px

    .post_carousel_card__description
      +max-screen($screen-lg-down)
        font-size: 14px

  &:hover
    color: $orange

  &__image
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    margin-bottom: 16px
    flex-grow: 1
    max-height: 211px

    +max-screen($screen-lg-down)
      max-height: 158px

    &_dark
      max-height: unset

  &__image_bg
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0

    border-radius: inherit
    background-size: cover
    background-position: 80%
    background-repeat: no-repeat

  &__title
    margin-bottom: 14px
    font-size: 24px
    position: relative
    z-index: 1

  &__description
    font-size: 16px
    position: relative
    z-index: 1
