@import '~/styles'

.button_more
    margin: 24px auto 15px auto
    border-radius: 4px
    height: 48px
    padding: 0 24px

    +max-screen($screen-md)
        margin: 16px auto 32px auto

.more
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    max-width: 100%
    width: 372px
    height: 186px
    background-size: contain
    background-position: center
    font-size: 30px
    margin: $padd auto
    cursor: pointer
    user-select: none
    background-repeat: no-repeat
    position: relative
    +max-screen($screen-lg-down)
        width: 274px
        height: 137px
        font-size: 23px
    &:before
        content: ''
        width: 100%
        height: 100%
        display: block
        position: absolute
        background-image: url("~/images/ellipse_green.svg")
        background-repeat: no-repeat
        background-size: contain
        background-position: center
        transition: 0.3s
    &Purple
        width: 100%
        height: 100%
        display: block
        position: absolute
        top: 0
        left: 0
        background-image: url("~/images/ellipse_purple.svg")
        background-repeat: no-repeat
        background-size: contain
        background-position: center
        transition: 0.3s
    span
        position: relative
        z-index: 1
    &:after
        content: ''
        width: 100%
        height: 100%
        display: block
        position: absolute
        background-image: url("~/images/ellipse_border.svg")
        background-repeat: no-repeat
        background-size: contain
        background-position: center
        transform: rotate(-6deg)
        transition: 0.3s
    &:hover
        &:before
            transform: rotate(-23deg)
            transition: 0.3s
        &:after
            transform: rotate(0)
            transition: 0.3s

