@import './vars'

%RICHTEXT-IMAGE
    FIGURE
        position: relative
        display: flex
        align-items: center
        flex-direction: column
        justify-content: center
        overflow: hidden
        border-radius: 4px
        margin: 0
        &:first-child
            margin-top: 0
        PICTURE
            display: block
            overflow: hidden
            position: relative
            height: 100%
            width: 100%
            border-radius: 4px
            DIV
                position: absolute
                top: 0
                left: 0
                width: 100%
                height: 100%
                background-position: center
                background-size: cover
                background-repeat: no-repeat
                background-image: none !important
                background-color: $neutral-50
            IMG
                position: absolute
                top: 0
                display: block
                margin: 0 auto
                width: 100%
                height: 100%

        &[class~="place-only_original"]
            picture
                padding-top: 0 !important
                padding-left: 1rem
                display: flex
                align-items: center
                div
                    display: none
                img
                    position: static
                    background-color: white
                    object-fit: contain
                    width: auto
            @media screen and (max-width: 1023px)
                position: relative !important
                margin: 0
                min-width: auto
                max-width: 320px
                picture
                    img
                        margin-left: 0
                        transform: translateX(0)
