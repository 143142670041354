@import '~/styles/'

.author_item
  background: $gray-light
  padding: 14px 16px
  border-radius: 4px

  display: flex
  align-items: center
  gap: 16px

  &__avatar
    display: block
    width: 52px
    height: 52px
    border-radius: 50%
    overflow: hidden
    flex-shrink: 0

  &__name
    margin-bottom: 1px

    +max-screen($screen-lg-down)
      margin-bottom: 2px
